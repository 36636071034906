import React, { Fragment, useContext, useState } from 'react'
import { Button, TextField, CardContent, Card, FormControl, FormGroup, Typography, Box, Grid, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'


// Icons
// import IconArrowBackIos from '@material-ui/icons/ArrowBackIosTwoTone'

// Anims
import AnimSuccessfull from '../../../res/anims/contact-successfull.json'

// Libs
import { FormattedMessage } from 'react-intl'
import { useSnackbar } from 'notistack'
import Lottie from 'react-lottie'

// Components
import Loader from '../../Components/Loader'

// Pages
import Header from './Header'
import Footer from './Footer'

// Others
import { projectName, langMessages, axiosSiteData, axiosSiteDataConfig } from '../../../variables'
import { UserConnectedContext, UtilitiesContext } from '../../../contexts'


function Company() {

    const { userConnected } = useContext(UserConnectedContext)
    const { APIError } = useContext(UtilitiesContext)
    document.title = projectName + ` - ${langMessages.messages?.[userConnected.lang]?.['Website.Menu.Contact'] || 'Contact Us'}`


    const theme = useTheme()
    const animSize = useMediaQuery(theme.breakpoints.up('md')) ? "800px" : "400px"
    const { enqueueSnackbar } = useSnackbar()

    const [record, setRecord] = useState({
        _Name: '', _Email: '', _Company: '', _Phone: '',
        _Motivation: '', _Message: ''
    })
    const [contentAction, setContentAction] = useState('default')   // default | working | success

    // Send the contact infos
    const handleSubmit = () => {

        // We make sure some fields aren't empty
        if (record._Name.trim() === '' || record._Email.trim() === '' || record._Company.trim() === '' || record._Phone.trim() === '' || record._Motivation.trim() === '' || record._Message.trim() === '') {
            enqueueSnackbar('Please fill in the form', { variant: 'info' })
            return
        }

        setContentAction('working')
        axiosSiteData.post(`/contacts`, record, axiosSiteDataConfig)
            .then(() => {
                setContentAction('success')
            })
            .catch(errors => {
                setContentAction('default')
                APIError(errors)
            })
    }


    return (
        <Fragment>
            <Header />
            <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1} py={10} px={4} bgcolor={theme.palette.background.default} >
                <Grid item xs={12}>
                    <Card>
                        {contentAction === 'success' &&
                            <CardContent>
                                <Box display="flex" flexDirection="column" alignItems="center" >
                                    <Typography variant="h4" style={{ marginBottom: theme.spacing(1), fontWeight: 'bold' }}>
                                        <FormattedMessage id="Website.Contact.Success" defaultMessage="We successfully received you contact request !" />
                                    </Typography>
                                    <Lottie
                                        options={{ loop: true, autoplay: true, animationData: AnimSuccessfull, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }}
                                        width={animSize}
                                    />
                                </Box>

                            </CardContent>}
                        {contentAction !== 'success' &&
                            <CardContent>
                                {/* Card Header, Title & Back Button */}
                                <Box display="flex" flexDirection="column" >
                                    <Typography variant="h3">
                                        <FormattedMessage id="Website.Contact.Title" defaultMessage="Contact Us" />
                                    </Typography>
                                    <Typography variant="subtitle1" style={{ marginTop: theme.spacing(2) }}>
                                        <FormattedMessage id="Website.Contact.Subtitle" defaultMessage="Please, give us more details by filling in this form" />
                                    </Typography>
                                </Box>
                                {/* Contacts Form */}
                                <form>


                                    {/** Field Name */}
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            label={<FormattedMessage id="Contacts.Columns._Name" defaultMessage="Name" />}
                                            id="_Name"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            required
                                            value={record._Name}
                                            onChange={event => setRecord({ ...record, _Name: event.target.value })}
                                        />
                                    </FormControl>


                                    {/** Field Email */}
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            label={<FormattedMessage id="Contacts.Columns._Email" defaultMessage="Email" />}
                                            id="_Email"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            required
                                            value={record._Email}
                                            onChange={event => setRecord({ ...record, _Email: event.target.value })}
                                        />
                                    </FormControl>


                                    {/** Field Company */}
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            label={<FormattedMessage id="Contacts.Columns._Company" defaultMessage="Company" />}
                                            id="_Company"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            required
                                            value={record._Company}
                                            onChange={event => setRecord({ ...record, _Company: event.target.value })}
                                        />
                                    </FormControl>


                                    {/** Field Phone */}
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            label={<FormattedMessage id="Contacts.Columns._Phone" defaultMessage="Phone" />}
                                            id="_Phone"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            required
                                            value={record._Phone}
                                            onChange={event => setRecord({ ...record, _Phone: event.target.value })}
                                        />
                                    </FormControl>


                                    {/** Field Motivation */}
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            label={<FormattedMessage id="Contacts.Columns._Motivation" defaultMessage="Motivation" />}
                                            id="_Motivation"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={record._Motivation}
                                            onChange={event => setRecord({ ...record, _Motivation: event.target.value })}
                                        />
                                    </FormControl>


                                    {/** Field Message */}
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            label={<FormattedMessage id="Contacts.Columns._Message" defaultMessage="Message" />}
                                            id="_Message"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            multiline
                                            rows={6}
                                            value={record._Message}
                                            onChange={event => setRecord({ ...record, _Message: event.target.value })}
                                        />
                                    </FormControl>



                                    {/** Button Submit */}
                                    <FormGroup row={true} style={{ marginTop: theme.spacing(4) }}>
                                        <Button variant="contained" color="primary" disabled={contentAction === 'working'} fullWidth onClick={() => handleSubmit()}>
                                            {contentAction === 'working' ? <Loader /> : <FormattedMessage id="FormSend" defaultMessage="SEND" />}
                                        </Button>
                                    </FormGroup>
                                </form>
                            </CardContent>}
                    </Card>
                </Grid>
            </Box>
            <Footer />
        </Fragment>
    )
}

export default Company