import React, { Fragment, useContext } from 'react'
import { Box } from '@material-ui/core'

// Libs
import BodyBuilder from './Builder/BodyBuilder'

// Pages
import Header from './Header'
import Footer from './Footer'

// Others
import { projectName, langMessages } from '../../../variables'
import { UserConnectedContext } from '../../../contexts'

function PrivacyPolicy() {

    const { userConnected } = useContext(UserConnectedContext)

    document.title = projectName + ` - ${langMessages.messages?.[userConnected.lang]?.['Website.Menu.Company'] || 'Company'}`

    return (
        <Fragment>
            <Header />
            <Box flexGrow={1}>
                <BodyBuilder bodyEntry="976a9fd1bfb846b481334d16395061b8" />
            </Box>
            <Footer />
        </Fragment>
    )
}

export default PrivacyPolicy