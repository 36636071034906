import React, { useEffect, useState, useContext, Fragment } from 'react'
import { Box, Button, DialogContentText, TextField } from '@material-ui/core'


// Others
import { DocumentsContext, PanelUserContext } from 'contexts'
import { axiosSiteData, axiosSiteDataConfig, GetDocumentLink, getFileExtension } from 'variables'

// Libs
import { FormattedMessage } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

import Dialoger from 'Views/Components/dialoger'
import DataTable from "Views/Components/DataTable"

// Views
import ContentForm from './ContentFormCustom'

//
// ─── ICONS ──────────────────────────────────────────────────────────────────────
import IconAdd from '@material-ui/icons/AddTwoTone'
import IconSearch from '@material-ui/icons/SearchTwoTone'

// API config

let searchTimeout = 0

function CustomRowsRender(rows) {

    return rows.map((aRecord) => (
        {
            ...aRecord,
            _Link: <a href={GetDocumentLink(aRecord._Link)} target="_blank" rel="noopener noreferrer"
            >Download</a>
        }
    ))
}

// Documents module
export default function Documents() {

    const [content, setContent] = useState('list')      // list | add | edit | delete
    const [selectedRecord, setSelectedRecord] = useState({})
    const [rendered, setRendered] = useState([])

    const { setDocuments } = useContext(DocumentsContext)

    useEffect(() => {

        axiosSiteData.get(`/documents/${(new Date()).toJSON()}`, axiosSiteDataConfig)
            .then(response => {
                setDocuments(response.data)
                setRendered(CustomRowsRender(response.data))
            })
            .catch(errors => console.log(errors))
    }, [setDocuments, content,])

    return (
        <Box m={3} p={1} flexGrow={1} display="flex" flexDirection="column">
            {/** Conditional list of UI to render */}
            {(content === 'list' || content === 'delete') &&
                <Listing setSelectedRecord={setSelectedRecord} setContent={setContent} rendered={rendered} setRendered={setRendered} />}
            {(content === 'add') && <Adding setContent={setContent} />}
            {(content === 'edit') && <Editing selectedRecord={selectedRecord} setContent={setContent} />}
            {content === 'delete' && <Deleting selectedRecord={selectedRecord} setContent={setContent} />}
        </Box>
    )
}

// List documents
function Listing({ setSelectedRecord, setContent, rendered, setRendered }) {

    // ─── CONTEXTS ───────────────────────────────────────────────────────────────────
    const { documents, setDocuments } = useContext(DocumentsContext)
    const { panelUser } = useContext(PanelUserContext)

    // ─── STATES ─────────────────────────────────────────────────────────────────────
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])

    // ─── Table Columns ───────────────────────────────────────────────────────────────────
    let columns = []
    columns = [
        { id: '_Name', numeric: false, label: <FormattedMessage id="Documents.Columns._Name" defaultMessage="Name" /> },
        { id: '_Link', numeric: false, label: <FormattedMessage id="Documents.Columns._Link" defaultMessage="Link" /> },
        ...panelUser.type === 'admin' ? [{ id: 'actions', numeric: false, label: <FormattedMessage id="TableActions" defaultMessage="Actions" /> }] : []
    ]

    console.log(columns)

    // ─── SEARCH ACTION ──────────────────────────────────────────────────────────────
    const searchAction = (toSearch) => {

        clearTimeout(searchTimeout)
        toSearch = toSearch.toLowerCase()
        setSearch(toSearch)
        searchTimeout = setTimeout(() => {
            if (toSearch.trim() === "") {
                axiosSiteData.get(`/documents/${(new Date()).toJSON()}`, axiosSiteDataConfig)
                    .then(response => {
                        setDocuments(response.data)
                        setRendered(CustomRowsRender(response.data))
                        setSearchResults([])
                    })
                    .catch(errors => console.log(errors))

                return
            }

            axiosSiteData.get(`/documents/search/${toSearch}`, axiosSiteDataConfig)
                .then(response => {
                    setSearchResults(response.data)
                })
                .catch(errors => console.log(errors))
        }, 200)
    }

    // ─── Edit Action ───────────────────────────────────────────────────────────────────
    const editAction = (row) => {
        setSelectedRecord(documents.filter(aRecord => {
            return aRecord._id === row._id
        })[0])
        setContent('edit')
    }

    // ─── Delete Action ───────────────────────────────────────────────────────────────────
    const deleteAction = (row) => {
        setSelectedRecord(row)
        setContent('delete')
    }

    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width={1} mb={2}>
                {/** Search bar */}
                <TextField
                    value={search}
                    onChange={event => searchAction(event.target.value)}
                    style={{ width: "40%" }}
                    variant="outlined"
                    label={<FormattedMessage id="TableSearchBar" defaultMessage="Search something ..." />}
                    InputProps={{
                        endAdornment: (
                            <IconSearch />
                        ),
                    }}
                />

                {/** Button Add */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setContent('add')}
                >
                    <IconAdd /> <FormattedMessage id="TableAdd" defaultMessage="ADD" />
                </Button>
            </Box>

            {/** DataTable for Documents */}
            <DataTable
                columns={columns}
                rows={search === '' ? rendered : searchResults}
                order="asc"
                orderBy=""
                editAction={editAction}
                deleteAction={deleteAction}
            />
        </Box>
    )
}

// Add document
function Adding({ setContent }) {

    const handleSubmit = (record, file) => {

        // We make sure some fields aren't empty
        if (record._Name.trim() === '' || file === null) {
            return false
        }

        // We upload the file
        const reader = new FileReader()
        reader.onabort = () => { return }
        reader.onerror = () => { return }
        reader.onload = () => {

            // We upload file
            axiosSiteData.post(`/files/documents/document-${uuidv4()}.${getFileExtension(file.name)}`, reader.result, axiosSiteDataConfig)
                .then((result) => {

                    // We now add the catalog with the file link
                    const theRecord = { ...record, _Link: result.data.result }
                    axiosSiteData.post(`/documents`, theRecord, axiosSiteDataConfig)
                        .then(() => {
                            setContent('list')
                        })
                        .catch(errors => console.log(errors))
                })
                .catch(errors => console.log(errors))
        }
        reader.readAsArrayBuffer(file)
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={{}} content="add" setContent={setContent} handleSubmit={handleSubmit} />
        </Fragment>
    )
}

// Update document
function Editing({ selectedRecord, setContent }) {

    const handleSubmit = (record, file) => {

        // We make sure some fields aren't empty
        if (record._Name.trim() === '' || (record._Link === '' && file === null)) {
            return false
        }

        if (record._Link !== '') {

            axiosSiteData.put(`/documents/${record._id}`, record, axiosSiteDataConfig)
                .then(() => {
                    setContent('list')
                })
                .catch(errors => console.log(errors))
            return
        }

        // We upload the file
        const reader = new FileReader()
        reader.onabort = () => { return }
        reader.onerror = () => { return }
        reader.onload = () => {

            // We upload file
            axiosSiteData.post(`/files/documents/document-${uuidv4()}.${getFileExtension(file.name)}`, reader.result, axiosSiteDataConfig)
                .then((result) => {

                    // We now add the catalog with the file link
                    const theRecord = { ...record, _Link: result.data.result }
                    axiosSiteData.put(`/documents/${record._id}`, theRecord, axiosSiteDataConfig)
                        .then(() => {
                            setContent('list')
                        })
                        .catch(errors => console.log(errors))
                })
                .catch(errors => console.log(errors))
        }
        reader.readAsArrayBuffer(file)
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={selectedRecord} content="edit" setContent={setContent}
                handleSubmit={handleSubmit} />
        </Fragment>
    )
}

// Delete document
function Deleting({ selectedRecord, setContent }) {

    const handleDelete = (setOpen) => {

        axiosSiteData.delete(`/documents/${selectedRecord._id}`, axiosSiteDataConfig)
            .then(() => {
                setOpen(false)
                setContent('list')
            })
            .catch(errors => console.log(errors))
    }

    return (
        <Dialoger
            title={`Document deletion`}
            content={
                <DialogContentText>Would you really like to delete this document ?</DialogContentText>
            }
            onClose={() => {
                setContent("list")
            }}
            onAction={handleDelete}
        />
    )
}
