import React, { useEffect, useState, useContext } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import {
	IconButton, Button, TextField, CardContent, Card, FormControl, FormGroup, Typography, Box, Grid,
	TableContainer, Table, TableCell,
	Paper,
	TableHead,
	TableBody,
	TableRow,
	CircularProgress,
	FormControlLabel
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import IconArrowBackIos from '@material-ui/icons/ArrowBackIosTwoTone'

import { PanelUserContext } from 'contexts'
import { axiosSiteData, axiosSiteDataConfig } from 'variables'

// Views
import Report from './Report'

// Components
import SwitchIOS from 'Views/Components/SwitchIOS'


export default function ContentForm({ selectedRecord, setContent }) {

	// ─── CONTEXTS ───────────────────────────────────────────────────────────────────
	const theme = useTheme()
	const { panelUser } = useContext(PanelUserContext)

	const [download, setDownload] = useState(false)
	const [exported, setExported] = useState(selectedRecord._Exported)


	// ─── FUNCTIONS ──────────────────────────────────────────────────────────────────
	const handleExported = (event) => {

		const bExported = event.target.checked

		axiosSiteData.put(`/quotations/${selectedRecord._id}`,
			{
				_Exported: bExported
			},
			axiosSiteDataConfig)
			.then(_ => setExported(bExported))
			.catch(errors => console.log(errors))
	}


	//
	// ─── EFFECTS ────────────────────────────────────────────────────────────────────
	useEffect(() => {

		setTimeout(() => {
			setDownload(true)
		}, 500)
	}, [])

	return (
		<Grid item xs={12}>
			<Card>
				<CardContent>
					{/* Card Header, Title & Back Button */}
					<Box display="flex" flexDirection="row" alignItems="center">
						<IconButton onClick={() => setContent('list')} style={{ marginRight: theme.spacing(1) }}>
							<IconArrowBackIos />
						</IconButton>
						<Typography variant="h5" style={{ flexGrow: 1 }}>
							<FormattedMessage id="Quotations.label" defaultMessage="Quotations" />
							&nbsp;
							<Typography component="span" variant="h5" color="primary">{`#${selectedRecord._Number}`}</Typography>
						</Typography>
						{panelUser.type === 'admin' &&
							<Typography variant="h5">{`${selectedRecord._User._Name}, ${selectedRecord._User._Company}`}</Typography>
						}
					</Box>
					{/* Quotations Form */}
					<form>
						<Grid container spacing={3} style={{ marginBottom: theme.spacing(0.001) }}>
							<Grid item xs={12} sm={8}>
								{/** Field Reference */}
								<FormControl fullWidth>
									<TextField
										label={<FormattedMessage id="Quotations.Columns._Reference" defaultMessage="Reference" />}
										id="_Reference"
										variant="outlined"
										disabled
										fullWidth
										value={selectedRecord._Reference}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={2}>
								{/** Field Date */}
								<FormControl fullWidth>
									<TextField
										label={<FormattedMessage id="Date" defaultMessage="Date" />}
										id="_CreatedAt"
										variant="outlined"
										fullWidth
										disabled
										value={new Date(selectedRecord._CreatedAt).toLocaleDateString()}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={2}>
								{/** Field Status */}
								<FormControl fullWidth>
									<TextField
										label={<FormattedMessage id="Quotations.Columns._Status" defaultMessage="Status" />}
										id="_Status"
										variant="outlined"
										fullWidth
										disabled
										value={selectedRecord._Status}
									/>
								</FormControl>
							</Grid>
						</Grid>

						{panelUser.type === 'admin' && <Grid container spacing={1} alignItems="center">
							<Grid item xs={12} md={10}>
								{/** Field Description */}
								<FormControl fullWidth margin="dense" style={{ flexGrow: 1 }}>
									<TextField
										label={<FormattedMessage id="Quotations.Columns._Description" defaultMessage="Description" />}
										id="_Description"
										variant="outlined"
										fullWidth
										margin="dense"
										required
										value={selectedRecord._Description}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={2}>
								{/** Exported */}
								<FormControlLabel
									control={<SwitchIOS checked={exported} onChange={handleExported} name="exported" />}
									label={<FormattedMessage id="Quotations.Columns._Exported" defaultMessage="Exported" />}
								/>
							</Grid>
						</Grid>}

						{/** Items */}
						<Box width={1} mt={1} p={1} borderRadius={4} border="outset">
							<Typography variant="body2" color="primary" style={{ fontStyle: 'italic', marginBottom: theme.spacing(1) }}>Items</Typography>

							{/** Selected products list */}
							<TableContainer component={Paper}>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell><FormattedMessage id="Products.Columns._Label" defaultMessage="Label"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Products.Columns._Description" defaultMessage="Description"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Products.Columns._Unit" defaultMessage="Unit"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Quotations.Columns._Items._Quantity" defaultMessage="Quantity"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Quotations.Columns._Items._ColorI" defaultMessage="Interior color"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Quotations.Columns._Items._ColorM" defaultMessage="Middle color"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Quotations.Columns._Items._ColorE" defaultMessage="Exterior color"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="TableActions" defaultMessage="Actions"></FormattedMessage ></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{selectedRecord._Items.map((anItem) => (
											<TableRow key={`${anItem._Label}|${anItem._Description}|${anItem._Serie}`}>
												<TableCell>{anItem._Label}</TableCell>
												<TableCell>{anItem._Description}</TableCell>
												<TableCell>{anItem._Unit}</TableCell>
												<TableCell>{anItem._Quantity}</TableCell>
												<TableCell>{anItem._ColorI}</TableCell>
												<TableCell>{anItem._ColorM}</TableCell>
												<TableCell>{anItem._ColorE}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						{/** Field Observations */}
						<FormControl fullWidth margin="normal">
							<TextField
								label={<FormattedMessage id="Quotations.Columns._Observations" defaultMessage="Observations" />}
								id="_Observations"
								variant="outlined"
								fullWidth
								multiline
								disabled
								value={selectedRecord._Observations}
							/>
						</FormControl>

						{/** Button EXPORT PDF */}
						{download && <PDFDownloadLink document={<Report record={selectedRecord} />} fileName={`Alumace quotation - ${selectedRecord._Number}.pdf`}>
							{({ blob, url, loading, error }) => (loading ?
								<FormGroup row={true} style={{ marginTop: theme.spacing(4) }}>
									<CircularProgress size={24} color="primary" />
								</FormGroup>
								:
								<FormGroup row={true} style={{ marginTop: theme.spacing(4) }}>
									<Button variant="contained" color="primary">
										<FormattedMessage id="FormExportPdf" defaultMessage=" EXPORT AS PDF" />
									</Button>
								</FormGroup>
							)}
						</PDFDownloadLink>}

					</form>
				</CardContent>
			</Card>
		</Grid>
	)
}