import React, { useState } from 'react'
import {
    IconButton, Button, TextField, CardContent, Card, FormControl, FormGroup, Typography, Box, Grid,
    InputLabel, Select, MenuItem, FormControlLabel
} from '@material-ui/core'
import SwitchIOS from '../../Components/SwitchIOS'

import { useTheme } from '@material-ui/core/styles'
import Loader from '../../Components/Loader'
import { FormattedMessage } from 'react-intl'

import IconArrowBackIos from '@material-ui/icons/ArrowBackIosTwoTone'


export default function ContentForm({ selectedRecord, content, setContent, handleSubmit }) {

    const [record, setRecord] = useState(selectedRecord)
    const [password, setPassword] = useState("")
    const [loader, setLoader] = useState(false)
    const theme = useTheme()

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    {/* Card Header, Title & Back Button */}
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <IconButton onClick={() => setContent('list')} style={{ marginRight: theme.spacing(1) }}>
                            <IconArrowBackIos />
                        </IconButton>
                        <Typography variant="h5">
                            {content === "add" ?
                                <FormattedMessage id="Users.FormTitleAdd" defaultMessage="Add a user" /> :
                                <FormattedMessage id="Users.FormTitleUpdate" defaultMessage="Edit user {record}" values={{ record: <Typography component="span" variant="h5" color="primary">{record._Name}</Typography> }} />
                            }
                        </Typography>
                    </Box>
                    {/* Users Form */}
                    <form>


                        {/** Field Name */}
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label={<FormattedMessage id="Users.Columns._Name" defaultMessage="Name" />}
                                id="_Name"
                                placeholder="Name"
                                variant="outlined"
                                fullWidth
                                required
                                value={record._Name}
                                onChange={event => setRecord({ ...record, _Name: event.target.value })}
                            />
                        </FormControl>


                        {/** Field Email */}
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label={<FormattedMessage id="Users.Columns._Email" defaultMessage="Email" />}
                                id="_Email"
                                placeholder="Email"
                                variant="outlined"
                                type="email"
                                fullWidth
                                required
                                disabled={content !== 'add' ? true : false}
                                value={record._Email}
                                onChange={event => setRecord({ ...record, _Email: event.target.value })}
                            />
                        </FormControl>


                        {/** Field Password */}
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label={<FormattedMessage id="Users.Columns._Password" defaultMessage="Password" />}
                                id="_Password"
                                placeholder="Password"
                                variant="outlined"
                                type="password"
                                fullWidth
                                required={content === 'add' ? true : false}
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                            />
                        </FormControl>


                        {/** Field Company */}
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label={<FormattedMessage id="Users.Columns._Company" defaultMessage="Company" />}
                                id="_Company"
                                placeholder="Company"
                                variant="outlined"
                                fullWidth
                                required
                                value={record._Company}
                                onChange={event => setRecord({ ...record, _Company: event.target.value })}
                            />
                        </FormControl>


                        {/** Field Phone */}
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label={<FormattedMessage id="Users.Columns._Phone" defaultMessage="Phone" />}
                                id="_Phone"
                                placeholder="Phone"
                                variant="outlined"
                                fullWidth
                                required
                                value={record._Phone}
                                onChange={event => setRecord({ ...record, _Phone: event.target.value })}
                            />
                        </FormControl>


                        {/** Field AccountID */}
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                {/** Account id pre default values */}
                                <FormControl required fullWidth variant="outlined" margin="normal">
                                    <InputLabel id="AccountID-pre-label">{<FormattedMessage id="Users.Columns._AccountID.Pre" defaultMessage="Account ID pre" />}</InputLabel>
                                    <Select
                                        id="_AccountID-pre"
                                        label={<FormattedMessage id="Users.Columns._AccountID.Pre" defaultMessage="Account ID pre" />}
                                        value={record._AccountID.split("-")[0]}
                                        onChange={event => setRecord({ ...record, _AccountID: event.target.value + "-" + record._AccountID.split("-")[1] })}
                                    >
                                        <MenuItem value="2111">2111</MenuItem>
                                        <MenuItem value="2112">2112</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={10}>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label={<FormattedMessage id="Users.Columns._AccountID" defaultMessage="AccountID" />}
                                        id="_AccountID"
                                        placeholder="AccountID"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        required
                                        value={record._AccountID.split("-")[1]}
                                        onChange={event => setRecord({
                                            ...record,
                                            _AccountID: record._AccountID.split("-")[0] + "-" + event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>


                        {/** Field Role */}
                        <FormControl required fullWidth variant="outlined" margin="normal">
                            <InputLabel id="Role-label">{<FormattedMessage id="Users.Columns._Role" defaultMessage="Role" />}</InputLabel>
                            <Select
                                id="_Role"
                                label={<FormattedMessage id="Users.Columns._Role" defaultMessage="Role" />}
                                value={record._Role}
                                onChange={event => setRecord({ ...record, _Role: event.target.value })}
                            >
                                <MenuItem value="customer">Customer</MenuItem>
                                <MenuItem value="admin">Admin</MenuItem>
                            </Select>
                        </FormControl>

                        {/** Active */}
                        <FormControlLabel
                            control={<SwitchIOS checked={record._Active} onChange={event => setRecord({ ...record, _Active: event.target.checked })} name="Active" />}
                            label={<FormattedMessage id="Products.Columns._Active" defaultMessage="Is Active ?" />}
                        />



                        {/** Button Submit */}
                        <FormGroup row={true} style={{ marginTop: theme.spacing(4) }}>
                            <Button variant="contained" color="primary" disabled={loader} fullWidth onClick={() => {

                                let theRecord
                                if (content === 'add') {
                                    theRecord = { ...record, _Password: password }
                                } else {
                                    if (password.trim() !== '') {
                                        theRecord = { ...record, _Password: password }
                                    } else {
                                        theRecord = record
                                    }
                                }
                                if (handleSubmit(theRecord) !== false) {
                                    setLoader(true)
                                }
                            }}>
                                {loader ? <Loader /> : (content === 'add' ? <FormattedMessage id="FormSave" defaultMessage="SAVE" /> : <FormattedMessage id="FormUpdate" defaultMessage="UPDATE" />)}
                            </Button>
                        </FormGroup>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}