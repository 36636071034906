import React, { useState, useCallback } from 'react'
import { IconButton, Button, TextField, CardContent, Card, FormControl, FormGroup, Typography, Box, Grid } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import { useTheme } from '@material-ui/core/styles'
import Loader from '../../Components/Loader'
import { FormattedMessage } from 'react-intl'

import IconArrowBackIos from '@material-ui/icons/ArrowBackIosTwoTone'
import IconDelete from '@material-ui/icons/DeleteTwoTone'


export default function ContentForm({ selectedRecord, content, setContent, handleSubmit }) {

    const [record, setRecord] = useState(selectedRecord)
    const [loader, setLoader] = useState(false)
    const [file, setFile] = useState(selectedRecord._Link)
    const theme = useTheme()

    // ─── FUNCTIONS ──────────────────────────────────────────────────────────────────
    const onDrop = useCallback(acceptedFiles => {

        acceptedFiles.forEach((theFile) => {

            setFile(theFile)
            return	// 1 file only
        })
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, maxFiles: 1, maxSize: 15728640,
        accept: 'application/pdf'
    })

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    {/* Card Header, Title & Back Button */}
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <IconButton onClick={() => setContent('list')} style={{ marginRight: theme.spacing(1) }}>
                            <IconArrowBackIos />
                        </IconButton>
                        <Typography variant="h5">
                            {content === "add" ?
                                <FormattedMessage id="FormTitleAdd" defaultMessage="Add a {collection}" values={{ collection: 'document' }} /> :
                                <FormattedMessage id="FormTitleUpdate" defaultMessage="Edit {collection} {record}" values={{ collection: 'document', record: <Typography component="span" variant="h5" color="primary">{record._Label}</Typography> }} />
                            }
                        </Typography>
                    </Box>
                    {/* Documents Form */}
                    <form>


                        {/** Field Name */}
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label={<FormattedMessage id="Documents.Columns._Name" defaultMessage="Name" />}
                                id="_Name"
                                placeholder="Name"
                                variant="outlined"
                                fullWidth
                                required
                                value={record._Name}
                                onChange={event => setRecord({ ...record, _Name: event.target.value })}
                            />
                        </FormControl>


                        {/** Field Link */}
                        <Box display="flex" mt={3} alignItems="flex-start">
                            {!file &&
                                <div {...getRootProps()}
                                    style={{
                                        backgroundColor: theme.palette.background.default, cursor: 'pointer', padding: theme.spacing(1)
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                            <p>Drop the files here ...</p> :
                                            <p>Drag & drop some files here, or click to select files</p>
                                    }
                                </div>}
                            {file &&
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => { setFile(null); setRecord({ ...record, _Link: '' }) }} >
                                        <IconDelete />
                                        <FormattedMessage id="Documents.RemoveFile" defaultMessage="Remove file" />
                                    </Button>
                                    <Typography variant="body1" color="secondary" style={{ marginLeft: theme.spacing(4) }}>{file.name}</Typography>
                                </Box>}
                        </Box>


                        {/** Button Submit */}
                        <FormGroup row={true} style={{ marginTop: theme.spacing(4) }}>
                            <Button variant="contained" color="primary" disabled={loader} fullWidth onClick={() => {
                                if (handleSubmit(record, file === '' ? null : file) !== false) {
                                    setLoader(true)
                                }
                            }}>
                                {loader ? <Loader /> : (content === 'add' ? <FormattedMessage id="FormSave" defaultMessage="SAVE" /> : <FormattedMessage id="FormUpdate" defaultMessage="UPDATE" />)}
                            </Button>
                        </FormGroup>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}