import React, { useState, useContext } from 'react'
import { CardContent } from '@material-ui/core'
import { Box, Typography, FormControl, TextField, Button, FormGroup } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

// Libs
import { FormattedMessage } from 'react-intl'
 import { useSnackbar } from 'notistack'

// Others
import { projectName, langMessages, axiosSiteData } from '../../../../variables'
import { UserConnectedContext, UtilitiesContext } from '../../../../contexts'

// Components
import Loader from '../../../Components/Loader'

function Step1({ setStep, record, setRecord }) {

    const theme = useTheme()
    const { userConnected } = useContext(UserConnectedContext)
    const { APIError } = useContext(UtilitiesContext)
    document.title = projectName + ` - ${langMessages.messages?.[userConnected.lang]?.['Website.PasswordReset.Title'] || 'Reset your password'}`

    const [working, setWorking] = useState(false)


    const handleSubmit = () => {
        if (record.email.trim() === '') return

        setWorking(true)
        axiosSiteData.post(`/reset`, { _User: record.email.trim() })
            .then(_ => {
                setRecord({...record, email: record.email.trim()})
                setStep(2)
            })
            .catch(errors => {
                setWorking(false)
                APIError(errors)
            })
    }

    return (

        <Box display="flex" flexDirection="column" >
            <Typography variant="h4" style={{ marginBottom: theme.spacing(2) }}>
                <FormattedMessage id="Website.PasswordReset.Title" defaultMessage="Reserved Area" />
            </Typography>

            {/* Login Form */}
            <form onSubmit={_ => handleSubmit()}>

                {/** Field Email */}
                <FormControl fullWidth margin="normal">
                    <TextField
                        label={<FormattedMessage id="Website.PasswordReset.Email" defaultMessage="Email" />}
                        id="_Email"
                        placeholder={langMessages.messages?.[userConnected.lang]?.['Website.PasswordReset.Email'] || 'Email'}
                        type="email"
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                        value={record.email}
                        onChange={event => setRecord({...record, email: event.target.value})}
                    />
                </FormControl>

                {/** Button Submit */}
                <FormGroup row={true} style={{ marginTop: theme.spacing(6) }}>
                    <Button size="large" variant="contained" color="primary" disabled={working} fullWidth onClick={() => handleSubmit()}>
                        {working ? <Loader /> : <FormattedMessage id="FormSend" defaultMessage="SEND" />}
                    </Button>
                </FormGroup>
            </form>
        </Box>


    )
}

function Step2({ setStep, record, setRecord, setContentAction }) {

    const theme = useTheme()
    const { userConnected } = useContext(UserConnectedContext)
    const { APIError } = useContext(UtilitiesContext)
    document.title = projectName + ` - ${langMessages.messages?.[userConnected.lang]?.['Website.PasswordReset.Code'] || 'Verification code'}`

    const [working, setWorking] = useState(false)


    const handleSubmit = () => {
        if (record.code.trim() === '') return

        setWorking(true)
        axiosSiteData.post(`/reset/check`, { _Context: 'alumace pwd reset', _User: record.email, _Code: record.code.trim() })
            .then(_ => {
                setRecord({...record, code: record.code.trim()})
                setStep(3)
            })
            .catch(errors => {
                setContentAction('default')
                APIError(errors)
            })

    }

    return (

        <Box display="flex" flexDirection="column" >
            <Typography variant="h4" style={{ marginBottom: theme.spacing(2) }}>
                <FormattedMessage id="Website.PasswordReset.Code" defaultMessage="Verification code" />
            </Typography>

            {/* Login Form */}
            <form onSubmit={_ => handleSubmit()}>

                {/** Field Code */}
                <FormControl fullWidth margin="normal">
                    <TextField
                        label={<FormattedMessage id="Website.PasswordReset.Code" defaultMessage="Verification code" />}
                        id="_Code"
                        placeholder={langMessages.messages?.[userConnected.lang]?.['Website.PasswordReset.Code'] || 'Verification code'}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                        value={record.code}
                        onChange={event => setRecord({...record, code: event.target.value})}
                    />
                </FormControl>

                {/** Button Submit */}
                <FormGroup row={true} style={{ marginTop: theme.spacing(6) }}>
                    <Button size="large" variant="contained" color="primary" disabled={working} fullWidth onClick={() => handleSubmit()}>
                        {working ? <Loader /> : <FormattedMessage id="FormSubmit" defaultMessage="SUBMIT" />}
                    </Button>
                </FormGroup>
            </form>
        </Box>


    )
}

function Step3({ record, setRecord, setContentAction }) {

    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar()
    const { userConnected } = useContext(UserConnectedContext)
    const { APIError } = useContext(UtilitiesContext)
    document.title = projectName + ` - ${langMessages.messages?.[userConnected.lang]?.['Website.PasswordReset.UpdatePassword'] || 'Update password'}`

    const [working, setWorking] = useState(false)


    const handleSubmit = () => {
        if (record.password === '' || record.confPassword === '') return
        
        if (record.password !== record.confPassword) {
            enqueueSnackbar(langMessages.messages?.[userConnected.lang]?.['Website.PasswordReset.NoMatch'] || "Passwords don't match", {variant: 'warning'})
            return
        }

        setWorking(true)
        axiosSiteData.post(`/users/reset/password`, {_Password: record.password, _ResetRequest: { _Context: 'alumace pwd reset', _User: record.email, _Code: record.code }})
            .then(_ => {
                enqueueSnackbar(langMessages.messages?.[userConnected.lang]?.['Website.PasswordReset.Success'] || "Password successfully updated", {variant: 'success'})
                setContentAction('default')
            })
            .catch(errors => {
                setContentAction('default')
                APIError(errors)
            })

    }

    return (

        <Box display="flex" flexDirection="column" >
            <Typography variant="h4" style={{ marginBottom: theme.spacing(2) }}>
                <FormattedMessage id="Website.PasswordReset.UpdatePassword" defaultMessage="Update password" />
            </Typography>

            {/* Login Form */}
            <form onSubmit={_ => handleSubmit()}>

                {/** Field Password */}
                <FormControl fullWidth margin="normal">
                    <TextField
                        label={<FormattedMessage id="Website.PasswordReset.Password" defaultMessage="Password" />}
                        id="_Password"
                        placeholder={langMessages.messages?.[userConnected.lang]?.['Website.PasswordReset.Password'] || 'Password'}
                        variant="outlined"
                        fullWidth
                        type="password"
                        size="small"
                        required
                        value={record.password}
                        onChange={event => setRecord({...record, password: event.target.value})}
                    />
                </FormControl>

                {/** Field Confirm password */}
                <FormControl fullWidth margin="normal">
                    <TextField
                        label={<FormattedMessage id="Website.PasswordReset.ConfirmPassword" defaultMessage="Confirm Password" />}
                        id="_ConfirmPassword"
                        placeholder={langMessages.messages?.[userConnected.lang]?.['Website.PasswordReset.ConfirmPassword'] || 'Confirm password'}
                        variant="outlined"
                        fullWidth
                        type="password"
                        size="small"
                        required
                        value={record.confPassword}
                        onChange={event => setRecord({...record, confPassword: event.target.value})}
                    />
                </FormControl>

                {/** Button Submit */}
                <FormGroup row={true} style={{ marginTop: theme.spacing(6) }}>
                    <Button size="large" variant="contained" color="primary" disabled={working} fullWidth onClick={() => handleSubmit()}>
                        {working ? <Loader /> : <FormattedMessage id="FormSubmit" defaultMessage="SUBMIT" />}
                    </Button>
                </FormGroup>
            </form>
        </Box>


    )
}

function PasswordReset({ setContentAction }) {

    const [step, setStep] = useState(1)
    const [record, setRecord] = useState({email: '', code: '', password: '', confPassword: ''})

    return (
        <CardContent>
            {step === 1 && <Step1 setStep={setStep} record={record} setRecord={setRecord} />}
            {step === 2 && <Step2 setStep={setStep} record={record} setRecord={setRecord} setContentAction={setContentAction} />}
            {step === 3 && <Step3 record={record} setRecord={setRecord} setContentAction={setContentAction} />}
        </CardContent>
    )
}


export default PasswordReset