import React, { useState, useEffect } from 'react'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    IconButton
} from '@material-ui/core'
import IconEdit from '@material-ui/icons/EditTwoTone'
import IconDelete from '@material-ui/icons/DeleteTwoTone'
import {makeStyles} from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

// Libs
import { v4 as uuidv4 } from 'uuid'
import InfiniteScroll from 'react-infinite-scroller'
import { CircularProgress } from '@material-ui/core'

const parentID = `datatable-${uuidv4().split('-')[0]}`
const inifiniteLoaderKey = `ifnl-${uuidv4().split('-')[0]}`
const headerHeight = 50
let tableHeight = headerHeight

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableHead: {
        fontSize: '1.2em',
        fontWeight: 600
    }
}))

// DataTable Component
export default function DataTable({columns, order, orderBy, rows, editAction, deleteAction, loadMoreFunc = null, hasMore = false }) {

    const [sort, setSort] = useState(order)
    const [sortBy, setSortBy] = useState(orderBy)

    const handleRequestSort = (event, property) => {
        const isAsc = sortBy === property && sort === 'asc'
        setSort(isAsc ? 'desc' : 'asc')
        setSortBy(property)
    }

    const handleColumnID = (row, columnID) => {
        return columnID.split('|').reduce((total, current) =>  total === '' ? row[current] : total[current], '')
    }

    useEffect(() => {

        // We get parent height
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                tableHeight = entry.contentRect.height
            }
          })

        resizeObserver.observe(document.getElementById(parentID)) 
    }, [])

    return (
        <Box flexGrow={1} id={parentID}>
            <TableContainer component={Paper} style={{maxHeight: tableHeight}}>
                <InfiniteScroll
                    pageStart={0} hasMore={hasMore} loadMore={loadMoreFunc}
                    loader={<CircularProgress size={20} key={inifiniteLoaderKey} />}
                    useWindow={false} threshold={300}
                    initialLoad={false}
                >
                    <Table>
                        {/* Table Header Row */}
                        <TableHeading
                            columns={columns}
                            order={sort}
                            orderBy={sortBy}
                            onRequestSort={handleRequestSort}
                        />
                        {/* Table Body */}
                        <TableBody>
                            {stableSort(rows, getComparator(sort, sortBy))
                                .map(row => (
                                    <TableRow
                                        hover
                                        key={row._id}
                                    >
                                        {columns.map(column => (
                                            column.id !== 'actions' ?
                                                <TableCell key={`${column.id}-${row._id}`} style={{padding: '9px'}}>
                                                    <React.Fragment>{handleColumnID(row, column.id)}</React.Fragment>
                                                </TableCell> :
                                                <TableCell key={`${column.id}-${row._id}`} style={{padding: '9px'}}>
                                                    {row._NotChangeable ? '' :
                                                        <IconButton
                                                            onClick={() => editAction(row)}
                                                        >
                                                            <IconEdit color="secondary"/>
                                                        </IconButton>
                                                    }
                                                    {deleteAction && <IconButton
                                                        onClick={() => deleteAction(row)}
                                                    >
                                                        <IconDelete color="secondary"/>
                                                    </IconButton>}
                                                </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            </TableContainer>
        </Box>
    )
}

// Descending Comparator
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

// Get Comparator
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

// Sort Columns
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

// DataTable Heading Row
function TableHeading({columns, order, orderBy, onRequestSort}) {
    const classes = useStyles()

    const sortHandler = (property) => (event) => {
        onRequestSort(event, property);
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map(column => (
                    <TableCell
                        className={classes.tableHead}
                        key={column.id}
                        sortDirection={orderBy === column.id ? order : false}
                        style={{padding: '9px', width: 'auto'}}
                    >
                        {column.id !== 'actions' ?
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'asc'}
                                onClick={(sortHandler(column.id))}
                            >
                                {column.label}
                                {orderBy === column.id ? (
                                        <span className={classes.visuallyHidden}>
                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>)
                                    : null
                                }
                            </TableSortLabel>
                            : column.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
