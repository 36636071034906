import React, { Fragment, useCallback } from 'react'
import { Box, Button, useTheme, Typography } from '@material-ui/core'
import { axiosSiteData, axiosSiteDataConfig, LoginBGLink } from 'variables'

// Icons
// import IconDelete from '@material-ui/icons/DeleteTwoTone'

// Components
import Loader from 'Views/Components/Loader'

// Libs
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import { useSnackbar } from 'notistack'

function LoginManager({ working, setWorking }) {

    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar


    const onDrop = useCallback(acceptedFiles => {

        acceptedFiles.forEach((theFile) => {
            const reader = new FileReader()

            reader.onabort = () => enqueueSnackbar("Image upload aborted", { variant: "error" })
            reader.onerror = () => enqueueSnackbar("Error while uploading image", { variant: "error" })
            reader.onload = () => {

                setWorking(true)
                // We upload file
                axiosSiteData.post(`/files/LoginBG.png`, reader.result, axiosSiteDataConfig)
                    .then(_ => {

                        // We now add the product with the pic link
                        caches.open('v1').then(function (cache) {
                            cache.delete(LoginBGLink).then(function (response) {
                                alert('The page will refresh to apply new Login background image data')
                                window.location.reload()
                            })
                        })
                    })
                    .catch(errors => {
                        setWorking(false)
                        console.log(errors)
                    })
            }
            reader.readAsArrayBuffer(theFile)
            return	// 1 file only
        })
    }, [setWorking, enqueueSnackbar])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop, maxFiles: 1, maxSize: 15728640,
        accept: 'image/png'
    })

    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
            <Typography color="primary" style={{ marginBottom: theme.spacing(2) }}><FormattedMessage id="Settings.LoginBG" defaultMessage="Login background image" /></Typography>
            <img src="https://s3.eu-central-1.wasabisys.com/3cs/Alumace/LoginBG.png" alt="Product" style={{ width: "100%", maxWidth: '400px', height: 'auto' }} />
            <Button
                style={{ marginTop: theme.spacing(1), maxWidth: 300 }}
                {...getRootProps()}
                variant="contained"
                color="primary"
                disabled={working}
            // onClick={() => { setImage('') }} style={{ marginTop: theme.spacing(1), maxWidth: 300 }}
            >
                {working ?
                    <Loader />
                    :
                    <Fragment>
                        <input {...getInputProps()} />
                        <FormattedMessage id="SelectImage" defaultMessage="Select an image" />
                    </Fragment>
                }

            </Button>
        </Box>
    )
}

export default LoginManager