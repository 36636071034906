import React, { useState } from 'react'
import { Grid, useMediaQuery, IconButton, Box, Dialog, Slide } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'

// Images
import Logo from '../../../res/images/logo.png'

// Libs
import { NavLink, Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

// Icons
import IconMenu from '@material-ui/icons/MenuTwoTone'

// Others
import { Button } from '@builder.io/react'

const useStyles = makeStyles(theme => ({

    headerRoot: {
        zIndex: 99,
        backgroundColor: theme.palette.background.default,
        display: 'flex', flexDirection: 'row', alignItems: 'center',
        boxShadow: theme.shadows[4],
        [theme.breakpoints.up('md')]: { padding: theme.spacing(1, 10) },
        [theme.breakpoints.down('md')]: { padding: theme.spacing(1, 3) }
    },
    navLlink: {
        color: theme.palette.text.primary,
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: theme.typography.subtitle1.fontSize,
        [theme.breakpoints.down('md')]: { fontSize: 30 },
        fontWeight: "bold",
        textDecoration: 'none',
        // paddingLeft: theme.spacing(12)
    },

    navLinkSelected: {
        color: theme.palette.primary.main,
        textDecorationLine: 'underline'
    }
}))

// AppMenuMobile is the menu of the mobile app
function AppMenuMobile({ openMobileMenu, setOpenMobileMenu }) {

    const theme = useTheme()
    const classes = useStyles()
    const inMobile = useMediaQuery(theme.breakpoints.down('md'))

    const handleClose = () => {
        setOpenMobileMenu(false)
    }

    return (
        <Dialog open={openMobileMenu && inMobile} fullScreen onClose={handleClose} TransitionComponent={TransitionMobileMenu}>
            <Box pt={4} display="flex" height={1} flexDirection="column" alignItems="center" justifyContent="space-between" bgcolor={theme.palette.background.default}>
                {/** Company */}
                <NavLink to="/company" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                        <FormattedMessage id="Website.Menu.Company" defaultMessage="Company" />
                    </NavLink>
                {/** Articles */}
                <NavLink to="/articles" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                        <FormattedMessage id="Website.Menu.Articles" defaultMessage="Articles" />
                    </NavLink>
                {/** Catalog */}
                <NavLink to="/catalogs" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                        <FormattedMessage id="Website.Menu.Catalog" defaultMessage="Catalog" />
                    </NavLink>
                {/** Reserved Area */}
                <NavLink to="/login" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                        <FormattedMessage id="Website.Menu.ReservedArea" defaultMessage="Reserved Area" />
                    </NavLink>
                {/** Contact */}
                <NavLink to="/contact-us" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                        <FormattedMessage id="Website.Menu.Contact" defaultMessage="Contact Us" />
                    </NavLink>
                    <Button color="primary" onClick={_ => setOpenMobileMenu(false)}>
                        Close
                    </Button>
            </Box>

        </Dialog>
    )
}

const TransitionMobileMenu = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

function Header() {

    const theme = useTheme()
    const classes = useStyles()
    const [openMobileMenu, setOpenMobileMenu] = useState(false)

    const inMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <header className={classes.headerRoot}>
            {/** Logo */}
            <Link to="/">
                <img height={useMediaQuery(theme.breakpoints.up('sm')) ? 64 : 48} src={Logo} alt="Alumace" />
            </Link>

            <Box flexGrow={1} />

            {/** Mobile Menu */}
            {inMobile &&
                <IconButton color="primary" style={{ marginRight: 'auto' }} onClick={_ => setOpenMobileMenu(true)}>
                    <IconMenu />
                </IconButton>
            }

            {/** Menu */}
            <nav >
                <AppMenuMobile openMobileMenu={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} />
                {!inMobile &&
                    <Grid container spacing={4}>
                        <Grid item>
                            {/** Company */}
                            <NavLink to="/company" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.Company" defaultMessage="Company" />
                            </NavLink>
                        </Grid>
                        <Grid item>
                            {/** Articles */}
                            <NavLink to="/articles" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.Articles" defaultMessage="Articles" />
                            </NavLink>
                        </Grid>
                        <Grid item>
                            {/** Catalog */}
                            <NavLink to="/catalogs" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.Catalog" defaultMessage="Catalog" />
                            </NavLink>
                        </Grid>
                        <Grid item>
                            {/** Reserved Area */}
                            <NavLink to="/login" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.ReservedArea" defaultMessage="Reserved Area" />
                            </NavLink>
                        </Grid>
                        <Grid item>
                            {/** Contact */}
                            <NavLink to="/contact-us" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.Contact" defaultMessage="Contact Us" />
                            </NavLink>
                        </Grid>
                        {/* <Grid item>

                            <NavLink to="/panel" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.Panel" defaultMessage="Panel" />
                            </NavLink>
                        </Grid> */}
                    </Grid>
                }
            </nav>

            {/** Switch Theme */}
            {/* <Tooltip title="Toggle Light/Dark Theme" >
                <IconButton
                    style={{ marginLeft: theme.spacing(2) }}
                    color="primary"
                    onClick={_ => {

                        if (userConnected.theme === 'light') {
                            setGvUserConnected({ ...userConnected, theme: 'dark' })
                        } else {
                            setGvUserConnected({ ...userConnected, theme: 'light' })
                        }
                        setUserConnected({ ...userConnected, theme: gvUserConnected.theme })
                    }}
                >
                    {userConnected.theme === 'light'
                        ?
                        <BrightnessLowTwoToneIcon />
                        :
                        <BrightnessMediumTwoToneIcon />
                    }
                </IconButton>
            </Tooltip> */}
        </header>
    )
}

export default Header