import React, { useContext } from 'react'
import { Button, Grid, Typography, Select, MenuItem, Box } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'

// Icons
import IconMail from '@material-ui/icons/MailTwoTone'
import IconPhone from '@material-ui/icons/PhoneTwoTone'
import IconLocation from '@material-ui/icons/PinDropTwoTone'

// Images

// Libs
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

// Others
import { gvUserConnected, langMessages, setGvUserConnected } from 'variables'
import { UserConnectedContext } from 'contexts'

const useStyles = makeStyles(theme => ({

    footerRoot: {
        backgroundColor: 'rgba(29, 29, 41, 0.8)',
        display: 'flex', flexDirection: 'column', alignItems: 'center',
    },
    footerSection: {
        [theme.breakpoints.up('lg')]: { padding: theme.spacing(5, 10) },
        [theme.breakpoints.down('lg')]: { padding: theme.spacing(5, 3) }
    },
    footerSection1: {
        [theme.breakpoints.up('lg')]: { padding: theme.spacing(2, 10) },
        [theme.breakpoints.down('lg')]: { padding: theme.spacing(2, 3) }
    },
    colorWhite: {
        color: 'white'
    },
    navLlink: {
        color: 'white',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        textDecoration: 'underline',
    },

    navLinkSelected: {
        color: theme.palette.primary.main,
        textDecorationLine: 'underline'
    },

    buttonTextTransform: {
        textTransform: 'none'
    }
}))

function Footer() {

    const theme = useTheme()
    const classes = useStyles()
    const { userConnected, setUserConnected } = useContext(UserConnectedContext)

    //
    // ─── FUNTIONS ───────────────────────────────────────────────────────────────────
    const handleLanguage = (event) => {
        setGvUserConnected({...gvUserConnected, lang: event.target.value})
        setUserConnected(gvUserConnected)
    }

    return (
        <footer className={classes.footerRoot}>
            <Grid container spacing={4} className={classes.footerSection} style={{ width: '100%', maxWidth: 1400 }}>

                {/** Headquarters */}
                <Grid item xs={12} md={6}>
                    <Grid container >
                        <Grid item >
                            <Typography variant="subtitle1" className={classes.colorWhite}>
                                <FormattedMessage id="Website.Footer.Headquarters" defaultMessage="Headquarters" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        {/** Email */}
                        <Grid item xs={12} md={6} >
                            <Button classes={{ root: classes.buttonTextTransform }} size="large" style={{ color: 'white' }}
                                onClick={() => window.open(`mailto:${langMessages.messages?.[userConnected.lang]?.['Website.Footer.Headquarters.Mail'] || ''}?subject=${langMessages.messages?.[userConnected.lang]?.['Website.Footer.Headquarters.MailSubject'] || ''}`, '_blank')}
                            >
                                <IconMail style={{ marginRight: theme.spacing(1), color: 'yellow' }} />
                                <FormattedMessage id="Website.Footer.Headquarters.Mail" defaultMessage="geral@alumace.pt" />
                            </Button>
                        </Grid>
                        {/** Phone */}
                        <Grid item xs={12} md={6} >
                            <Button classes={{ root: classes.buttonTextTransform }} size="large" style={{ color: 'white' }}
                                onClick={() => window.open(`tel:${langMessages.messages?.[userConnected.lang]?.['Website.Footer.Headquarters.Phone'] || ''}`)}
                            >
                                <IconPhone style={{ marginRight: theme.spacing(1), color: 'yellow' }} />
                                <FormattedMessage id="Website.Footer.Headquarters.Phone" defaultMessage="+351 219 809 710" />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {/** Address */}
                        <Grid item xs={12}>
                            <Button classes={{ root: classes.buttonTextTransform }} size="large" style={{ color: 'white' }}
                                onClick={() => window.open(`https://maps.google.com/?q=${langMessages.messages?.[userConnected.lang]?.['Website.Footer.Headquarters.Address'] || ''}`, '_blank')}
                            >
                                <IconLocation style={{ marginRight: theme.spacing(1), color: 'yellow' }} />
                                <FormattedMessage id="Website.Footer.Headquarters.Address" defaultMessage="Avª de Lisboa, 41 2605-801 CASAL DE CAMBRA PORTUGAL" />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                {/** Subsidiary */}
                <Grid item xs={12} md={6}>
                    <Grid container >
                        <Grid item >
                            <Typography variant="subtitle1" className={classes.colorWhite}>
                                <FormattedMessage id="Website.Footer.Subsidiary" defaultMessage="Subsidiary" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        {/** Email */}
                        <Grid item xs={12} md={6} >
                            <Button classes={{ root: classes.buttonTextTransform }} size="large" style={{ color: 'white' }}
                                onClick={() => window.open(`mailto:${langMessages.messages?.[userConnected.lang]?.['Website.Footer.Subsidiary.Mail'] || ''}?subject=${langMessages.messages?.[userConnected.lang]?.['Website.Footer.Subsidiary.MailSubject'] || ''}`, '_blank')}
                            >
                                <IconMail style={{ marginRight: theme.spacing(1), color: 'yellow' }} />
                                <FormattedMessage id="Website.Footer.Subsidiary.Mail" defaultMessage="filial@alumace.pt" />
                            </Button>
                        </Grid>
                        {/** Phone */}
                        <Grid item xs={12} md={6} >
                            <Button classes={{ root: classes.buttonTextTransform }} size="large" style={{ color: 'white' }}
                                onClick={() => window.open(`tel:${langMessages.messages?.[userConnected.lang]?.['Website.Footer.Subsidiary.Phone'] || ''}`)}
                            >
                                <IconPhone style={{ marginRight: theme.spacing(1), color: 'yellow' }} />
                                <FormattedMessage id="Website.Footer.Subsidiary.Phone" defaultMessage="+351 212 258 544" />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {/** Address */}
                        <Grid item xs={12}>
                            <Button classes={{ root: classes.buttonTextTransform }} size="large" style={{ color: 'white' }}
                                onClick={() => window.open(`https://maps.google.com/?q=${langMessages.messages?.[userConnected.lang]?.['Website.Footer.Subsidiary.Address'] || ''}`, '_blank')}
                            >
                                <IconLocation style={{ marginRight: theme.spacing(1), color: 'yellow' }} />
                                <FormattedMessage id="Website.Footer.Subsidiary.Address" defaultMessage="Rua António Pedro Mendonça, 54A 2840-162 SEIXAL PORTUGAL" />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {/** Language */}
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" mt={2}>
                                <Typography variant="subtitle1" className={classes.colorWhite} style={{ marginRight: theme.spacing(2) }}>
                                    <FormattedMessage id="Language" defaultMessage="Language" />
                                </Typography>
                                <Select
                                    style={{ backgroundColor: 'white' }}
                                    value={userConnected.lang}
                                    margin="dense"
                                    onChange={handleLanguage}
                                    variant="outlined"
                                >
                                    {langMessages.locales.map(lang => (
                                        <MenuItem key={lang} value={lang}>{lang}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            {/** use full links */}
            <Grid container className={classes.footerSection1} style={{ backgroundColor: 'rgba(29, 29, 41, 1)' }}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={1}>
                        <Grid item>
                            {/** Company */}
                            <NavLink to="/company" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.Company" defaultMessage="Company" />
                            </NavLink>
                        </Grid>
                        <Grid item>
                            {/** Articles */}
                            <NavLink to="/articles" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.Articles" defaultMessage="Articles" />
                            </NavLink>
                        </Grid>
                        <Grid item>
                            {/** Catalog */}
                            <NavLink to="/catalogs" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.Catalog" defaultMessage="Catalog" />
                            </NavLink>
                        </Grid>
                        <Grid item>
                            {/** Reserved Area */}
                            <NavLink to="/login" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.ReservedArea" defaultMessage="Reserved Area" />
                            </NavLink>
                        </Grid>
                        <Grid item>
                            {/** Contact */}
                            <NavLink to="/contact-us" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.Contact" defaultMessage="Contact Us" />
                            </NavLink>
                        </Grid>
                        <Grid item>
                            {/** Privacy Policy */}
                            <NavLink to="/privacy-policy" className={classes.navLlink} activeClassName={classes.navLinkSelected}>
                                <FormattedMessage id="Website.Menu.PrivacyPolicy" defaultMessage="Privacy Policy" />
                            </NavLink>
                        </Grid>
                    </Grid>
                    {/** Copyright*/}
                    <Grid container justify="center" spacing={1} style={{ marginTop: theme.spacing(1) }}>
                        <Grid item>
                            <Typography variant="subtitle2" align="center" style={{ color: 'white' }}>
                                <FormattedMessage id="Website.Footer.Copyright" defaultMessage="@2021 - ALUMACE - Aluminums and Accessories. Lda." />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </footer>
    )
}

export default Footer