import React, { useState, useContext, useEffect, useCallback } from 'react'
import {
	IconButton, Button, TextField, CardContent, Card, FormControl, FormGroup, Typography, Box, Grid, InputLabel,
	Select, MenuItem, FormControlLabel
} from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import { useTheme } from '@material-ui/core/styles'
import Loader from '../../Components/Loader'
import SwitchIOS from '../../Components/SwitchIOS'
import { FormattedMessage } from 'react-intl'

import IconArrowBackIos from '@material-ui/icons/ArrowBackIosTwoTone'
import IconDelete from '@material-ui/icons/DeleteTwoTone'
import { SeriesContext } from 'contexts'
import { axiosSiteData, axiosSiteDataConfig } from 'variables'



export default function ContentForm({ selectedRecord, content, setContent, handleSubmit }) {



	const [record, setRecord] = useState(selectedRecord)
	const [loader, setLoader] = useState(false)
	const [image, setImage] = useState(selectedRecord._Picture)
	const [file, setFile] = useState()
	const theme = useTheme()

	const { series, setSeries } = useContext(SeriesContext)

	const units = ["mt", "un", "5,8", "6,0", "6,1", "6,5", "6,0 6,5"]

	// ─── FUNCTIONS ──────────────────────────────────────────────────────────────────
	const onDrop = useCallback(acceptedFiles => {

		acceptedFiles.forEach((theFile) => {
			const reader = new FileReader()

			reader.onabort = () => setImage('')
			reader.onerror = () => setImage('')
			reader.onload = () => {
				// Do whatever you want with the file contents
				setFile(theFile)
				setImage(reader.result)
			}
			reader.readAsDataURL(theFile)
			return	// 1 file only
		})
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop, maxFiles: 1, maxSize: 15728640,
		accept: 'image/jpeg, image/png'
	})

	// ─── GET SERIES ─────────────────────────────────────────────────────────────────
	useEffect(() => {
		axiosSiteData.get(`/series/${(new Date()).toJSON()}`, axiosSiteDataConfig)
			.then(response => {
				setSeries(response.data)
			})
	}, [setSeries])

	return (
		<Grid item xs={12}>
			<Card>
				<CardContent>
					{/* Card Header, Title & Back Button */}
					<Box display="flex" flexDirection="row" alignItems="center">
						<IconButton onClick={() => setContent('list')} style={{ marginRight: theme.spacing(1) }}>
							<IconArrowBackIos />
						</IconButton>
						<Typography variant="h5">
							{content === "add" ?
								<FormattedMessage id="Products.FormTitleAdd" defaultMessage="Add a product" /> :
								<FormattedMessage id="Products.FormTitleUpdate" defaultMessage="Edit product {record}" values={{ record: <Typography component="span" variant="h5" color="primary">{record._Label}</Typography> }} />
							}
						</Typography>
					</Box>
					{/* Products Form */}
					<form style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>

						{/** Active and HasColor */}
						<FormGroup row>
							<FormControlLabel
								control={<SwitchIOS checked={record._Active} onChange={event => setRecord({ ...record, _Active: event.target.checked })} name="active" />}
								label={<FormattedMessage id="Products.Columns._Active" defaultMessage="Is active ?" />}
							/>
							<FormControlLabel
								control={<SwitchIOS checked={record._HasColor} onChange={event => setRecord({ ...record, _HasColor: event.target.checked })} name="hasColor" />}
								label={<FormattedMessage id="Products.Columns._HasColor" defaultMessage="Has color ?" />}
							/>
						</FormGroup>

						<Box display="flex" mt={3} alignItems="flex-start">
							{image === '' &&
								<div {...getRootProps()}
									style={{
										backgroundColor: theme.palette.background.default, cursor: 'pointer', padding: theme.spacing(1)
									}}
								>
									<input {...getInputProps()} />
									{
										isDragActive ?
											<p>Drop the files here ...</p> :
											<p>Drag & drop some files here, or click to select files</p>
									}
								</div>}
							{image !== '' &&
								<Box display="flex" flexDirection="column">
									<img src={image} alt="Product" style={{ width: "100%", maxWidth: '400px', height: 'auto' }} />
									<Button 
									variant="outlined"
									color="secondary" 
									onClick={() => {setImage(''); setRecord({...record, _Picture: ''})}} style={{ marginTop: theme.spacing(1), justifySelf: 'center' }}>
										<IconDelete />
										<FormattedMessage id="Products.RemoveImage" defaultMessage="Remove image" />
									</Button>
								</Box>
								
							}

							<Box ml={6} width={1}>
								{/** Field Serie */}
								<FormControl required fullWidth variant="outlined" margin="normal">
									<InputLabel id="_Serie-label">{<FormattedMessage id="Products.Columns._Serie" defaultMessage="Serie" />}</InputLabel>
									<Select
										id="_Serie"
										label={<FormattedMessage id="Products.Columns._Serie" defaultMessage="Serie" />}
										value={record._Serie}
										onChange={event => setRecord({ ...record, _Serie: event.target.value })}
									>
										{series.map(aSerie => (
											<MenuItem key={aSerie._id} value={aSerie._Label}>{aSerie._Label}</MenuItem>
										))}
									</Select>
								</FormControl>

								{/** Field Label */}
								<FormControl fullWidth margin="normal">
									<TextField
										label={<FormattedMessage id="Products.Columns._Label" defaultMessage="Label" />}
										id="_Label"
										placeholder="Label"
										variant="outlined"
										fullWidth
										required
										value={record._Label}
										onChange={event => setRecord({ ...record, _Label: event.target.value })}
									/>
								</FormControl>


								{/** Field Description */}
								<FormControl fullWidth margin="normal">
									<TextField
										label={<FormattedMessage id="Products.Columns._Description" defaultMessage="Description" />}
										id="_Description"
										placeholder="Description"
										variant="outlined"
										fullWidth
										required
										multiline
										value={record._Description}
										onChange={event => setRecord({ ...record, _Description: event.target.value })}
									/>
								</FormControl>

								<Box display="flex">
									{/** Field Unit */}
									<FormControl required fullWidth variant="outlined" margin="normal">
										<InputLabel id="_Unit-label">{<FormattedMessage id="Products.Columns._Unit" defaultMessage="Unit" />}</InputLabel>
										<Select
											id="_Unit"
											label={<FormattedMessage id="Products.Columns._Unit" defaultMessage="Unit" />}
											value={record._Unit}
											onChange={event => setRecord({ ...record, _Unit: event.target.value })}
										>
											{units.map(aUnit => (
												<MenuItem key={aUnit} value={aUnit}>{aUnit}</MenuItem>
											))}
										</Select>
									</FormControl>
									{/** Field Price */}
									<FormControl fullWidth margin="normal" style={{ marginLeft: theme.spacing(3) }}>
										<TextField
											label={<FormattedMessage id="Products.Columns._Price" defaultMessage="Price" />}
											id="_Price"
											placeholder="Price"
											variant="outlined"
											fullWidth
											type="number"
											required
											value={record._Price}
											onChange={event => setRecord({ ...record, _Price: parseFloat(event.target.value) })}
										/>
									</FormControl>
								</Box>

								{/** Colors */}
								<FormGroup row>
									<FormControlLabel
										control={<SwitchIOS checked={record._ColorI} onChange={event => setRecord({ ...record, _ColorI: event.target.checked })} name="colorI" />}
										label={<FormattedMessage id="Products.Columns._ColorI" defaultMessage="Color I" />}
									/>
									<FormControlLabel
										control={<SwitchIOS checked={record._ColorM} onChange={event => setRecord({ ...record, _ColorM: event.target.checked })} name="colorM" />}
										label={<FormattedMessage id="Products.Columns._ColorM" defaultMessage="Color M" />}
									/>
									<FormControlLabel
										control={<SwitchIOS checked={record._ColorE} onChange={event => setRecord({ ...record, _ColorE: event.target.checked })} name="colorE" />}
										label={<FormattedMessage id="Products.Columns._ColorE" defaultMessage="Color E" />}
									/>
								</FormGroup>
							</Box>
						</Box>

						{/** Button Submit */}
						<FormGroup row={true} style={{ marginTop: theme.spacing(4) }}>
							<Button variant="contained" color="primary" disabled={loader} fullWidth onClick={() => {
								if (handleSubmit(record, image === '' ? null : file) !== false) {
									setLoader(true)
								}
							}}>
								{loader ? <Loader /> : (content === 'add' ? <FormattedMessage id="FormSave" defaultMessage="SAVE" /> : <FormattedMessage id="FormUpdate" defaultMessage="UPDATE" />)}
							</Button>
						</FormGroup>
					</form>
				</CardContent>
			</Card>
		</Grid>
	)
}