import React, {useEffect, useState, useContext, Fragment} from 'react'
import { Box, Button, DialogContentText, TextField, Typography  } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import {ContactsContext} from '../../../contexts'
import { axiosSiteData, axiosSiteDataConfig } from '../../../variables'
import Dialoger from '../../Components/dialoger'
import DataTable from "../../Components/DataTable"
import ContentForm from './ContentFormCustom'

//
// ─── ICONS ──────────────────────────────────────────────────────────────────────
import IconAdd from '@material-ui/icons/AddTwoTone'
import IconSearch from '@material-ui/icons/SearchTwoTone'

// API config

let searchTimeout = 0

const useStyles = makeStyles(theme => ({
    tableImage: {
        width: "100%", maxWidth: 150, height: 'auto'
    },
    boolTextYes: {
        color: '#00A4FF'
    },
    boolTextNo: {
        color: '#ff3f34'
    }
}))


// CustomRowsRender
/* function CustomRowsRender(rows) {
    
    return rows.map((aRecord) => (
        {
            ...aRecord,
            _Converted: <Typography variant="body1" className={aRecord._Converted ? classes.boolTextYes : classes.boolTextNo}>{aRecord._Converted ? 'Yes' : 'No'}</Typography>
        }
    ))
} */

// Contacts module
export default function Contacts() {

    const classes = useStyles()

    const [content, setContent] = useState('list')      // list | add | edit | delete
    const [selectedRecord, setSelectedRecord] = useState({})
    const [rendered, setRendered] = useState([])

    const { setContacts } = useContext(ContactsContext)

    useEffect(() => {
        axiosSiteData.get(`/contacts/${(new Date()).toJSON()}`, axiosSiteDataConfig)
            .then(response => {
                setContacts(response.data)
                setRendered(response.data.map((aRecord) => (
                    {
                        ...aRecord,
                        _Converted: <Typography variant="body1" className={aRecord._Converted ? classes.boolTextYes : classes.boolTextNo}>{aRecord._Converted ? 'Yes' : 'No'}</Typography>
                    }
                )))
            })
    }, [setContacts, content, classes])

    return (
        <Box m={3} p={1} flexGrow={1} display="flex" flexDirection="column">
            {/** Conditional list of UI to render */}
            {(content === 'list' || content === 'delete') &&
            <Listing setSelectedRecord={setSelectedRecord} setContent={setContent} rendered={rendered} setRendered={setRendered}/>}
            {/* (content === 'add') && <Adding setContent={setContent}/> */}
            {(content === 'edit') && <Editing selectedRecord={selectedRecord} setContent={setContent}/>}
            {content === 'delete' && <Deleting selectedRecord={selectedRecord} setContent={setContent}/>}
        </Box>
    )
}

// List contacts
function Listing({setSelectedRecord, setContent, rendered, setRendered }) {

    // ─── CONTEXTS ───────────────────────────────────────────────────────────────────
    const classes = useStyles()
    const { contacts, setContacts } = useContext(ContactsContext)

    // ─── STATES ─────────────────────────────────────────────────────────────────────
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])

    // ─── Table Columns ───────────────────────────────────────────────────────────────────
    let columns = []
    columns = [
		{id: '_Name', numeric: false, label: <FormattedMessage id="Contacts.Columns._Name" defaultMessage="Name" />},
		{id: '_Email', numeric: false, label: <FormattedMessage id="Contacts.Columns._Email" defaultMessage="Email" />},
		{id: '_Company', numeric: false, label: <FormattedMessage id="Contacts.Columns._Company" defaultMessage="Company" />},
		{id: '_Phone', numeric: false, label: <FormattedMessage id="Contacts.Columns._Phone" defaultMessage="Phone" />},
		{id: '_Motivation', numeric: false, label: <FormattedMessage id="Contacts.Columns._Motivation" defaultMessage="Motivation" />},
		{id: '_Message', numeric: false, label: <FormattedMessage id="Contacts.Columns._Message" defaultMessage="Message" />},
		{id: '_Converted', numeric: false, label: <FormattedMessage id="Contacts.Columns._Converted" defaultMessage="Converted" />},
		{id: 'actions', numeric: false, label: <FormattedMessage id="TableActions" defaultMessage="Actions" />}
	]

    // ─── SEARCH ACTION ──────────────────────────────────────────────────────────────
    const searchAction = (toSearch) => {

        clearTimeout(searchTimeout)
        toSearch = toSearch.toLowerCase()
        setSearch(toSearch)
        searchTimeout = setTimeout(() => {
            if (toSearch.trim() === "") {
                axiosSiteData.get(`/contacts/${(new Date()).toJSON()}`, axiosSiteDataConfig)
                    .then(response => {
                        setContacts(response.data)
                        setRendered(response.data.map((aRecord) => (
                            {
                                ...aRecord,
                                _Converted: <Typography variant="body1" className={aRecord._Converted ? classes.boolTextYes : classes.boolTextNo}>{aRecord._Converted ? 'Yes' : 'No'}</Typography>
                            }
                        )))
                        setSearchResults([])
                    })
    
                return
            }
    
            axiosSiteData.get(`/contacts/search/${toSearch}`, axiosSiteDataConfig)
                .then(response => {
                    setSearchResults(response.data.map((aRecord) => (
                        {
                            ...aRecord,
                            _Converted: <Typography variant="body1" className={aRecord._Converted ? classes.boolTextYes : classes.boolTextNo}>{aRecord._Converted ? 'Yes' : 'No'}</Typography>
                        }
                    )))
                })
        }, 200)
    }

    // ─── Edit Action ───────────────────────────────────────────────────────────────────
    const editAction = (row) => {
        setSelectedRecord(contacts.filter(aRecord => {
            return aRecord._id === row._id
        })[0])
        setContent('edit')
    }

    // ─── Delete Action ───────────────────────────────────────────────────────────────────
    const deleteAction = (row) => {
        setSelectedRecord(row)
        setContent('delete')
    }

    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width={1} mb={2}>
                {/** Search bar */}
                <TextField
                    value={search}
                    onChange={event => searchAction(event.target.value)}
                    style={{ width: "40%" }}
                    variant="outlined"
                    label={<FormattedMessage id="TableSearchBar" defaultMessage="Search something ..." />}
                    InputProps={{
                        endAdornment: (
                            <IconSearch />
                        ),
                    }}
                />

                {/** Button Add */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setContent('add')}
                >
                    <IconAdd /> <FormattedMessage id="TableAdd" defaultMessage="ADD" />
                </Button>
            </Box>

            {/** DataTable for Contacts */}
            <DataTable
                columns={columns}
                rows={search === '' ? rendered : searchResults}
                order="asc"
                orderBy="_Code"
                editAction={editAction}
                deleteAction={deleteAction}
            />
        </Box>
    )
}

// Add contact
/* function Adding({setContent}) {

    const handleSubmit = (record) => {

        // We make sure some fields aren't empty
        if (record._Name.trim() === '' || record._Email.trim() === '' || record._Company.trim() === '' || record._Phone.trim() === '' || record._Motivation.trim() === '' || record._Message.trim() === '') {
            return false
        }

        axiosSiteData.post(`/contacts`, record, axiosSiteDataConfig)
            .then(() => {
                setContent('list')
            })
            .catch(errors => console.log(errors))
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={{}} content="add" setContent={setContent} handleSubmit={handleSubmit}/>
        </Fragment>
    )
} */

// Update contact
function Editing({selectedRecord, setContent}) {

    const handleSubmit = (record) => {

        /* if (record._Name.trim() === '' || record._Email.trim() === '' || record._Company.trim() === '' || record._Phone.trim() === '' || record._Motivation.trim() === '' || record._Message.trim() === '') {
            return false
        } */

        axiosSiteData.put(`/contacts/${record._id}/convert`, record, axiosSiteDataConfig)
            .then(_ => setContent('list'))
            .catch(errors => console.log(errors))
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={selectedRecord} content="edit" setContent={setContent}
                         handleSubmit={handleSubmit}/>
        </Fragment>
    )
}

// Delete contact
function Deleting({selectedRecord, setContent}) {

    const handleDelete = (setOpen) => {

        axiosSiteData.delete(`/contacts/${selectedRecord._id}`, axiosSiteDataConfig)
            .then(() => {
                setOpen(false)
                setContent('list')
            })
            .catch(errors => console.log(errors))
    }

    return (
        <Dialoger
            title={`Contact deletion`}
            content={
                <DialogContentText>Would you really like to delete this contact ?</DialogContentText>
            }
            onClose={() => {
                setContent("list")
            }}
            onAction={handleDelete}
        />
    )
}
