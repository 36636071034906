import React, { useState, useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    CssBaseline,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Box,
    useMediaQuery,
    Dialog, Slide
} from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'

// Views
import Settings from './Settings/Settings'

import {
    PanelUserContext,
    UtilitiesContext,
    UsersContext,
    SeriesContext,
    ColorsContext,
    CatalogsContext,
    ProductsContext,
    ContactsContext,
    OrdersContext,
    QuotationsContext,
    DocumentsContext
} from '../../contexts'
import { axiosSiteData, axiosSiteDataConfig, gvUserConnected, setGvUserConnected, projectName, langMessages } from '../../variables'

// Libs
import { Link } from 'react-router-dom'

// Views
import Splash from './Splash'

// Images
import Logo from '../../res/images/logo.png'

// Icons
import IconClose from '@material-ui/icons/CloseTwoTone'
import IconMenu from '@material-ui/icons/MenuTwoTone'

//
// ────────────────────────────────────────────────── I ──────────
//   :::::: I C O N S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────
//
// import BrightnessLowTwoToneIcon from '@material-ui/icons/BrightnessLowTwoTone';
// import BrightnessMediumTwoToneIcon from '@material-ui/icons/BrightnessMediumTwoTone';
import IconSettings from '@material-ui/icons/SettingsTwoTone'
import IconLogout from '@material-ui/icons/ExitToAppTwoTone'

//
// ────────────────────────────────────────────────── I ──────────
//   :::::: P A G E S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────
//

import Dashboard from './Dashboard/Dashboard'
import Users from './Users/UsersCustom'
import Series from './Series/Series'
import Colors from './Colors/Colors'
import Catalogs from './Catalogs/CatalogsCustom'
import Documents from './Documents/DocumentsCustom'
import Products from './Products/ProductsCustom'
import Contacts from './Contacts/ContactsCustom'
import Orders from './Orders/OrdersCustom'
import Quotations from './Quotations/QuotationsCustom'




const drawerWidth = 240

//
// ──────────────────────────────────────────────────── I ──────────
//   :::::: S T Y L E S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        color: 'white',
        backgroundColor: '#363636'
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        marginLeft: drawerWidth,
        backgroundColor: theme.palette.primary.main
    },
    toolbar: theme.mixins.toolbar,
    content: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        flexGrow: 1,
        backgroundColor: theme.palette.background.default
    }
}))
// ─────────────────────────────────────────────────────────────── END STYLES ─────

// AppMenu is the Menu of the app
function AppMenu({ currentBody, onSelectedMenu }) {

    //
    // ─── CONTEXTS ───────────────────────────────────────────────────────────────────
    const classes = useStyles()
    const { panelUser } = useContext(PanelUserContext)

    //
    // ─── STATES ─────────────────────────────────────────────────────────────────────
    // const [currentTab, setCurrentTab] = useState(0)

    const fullMenu = [
        { id: 'dashboard', name: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />, component: <Dashboard />, roles: ['admin', 'customer'] },
        { id: 'users', name: <FormattedMessage id="Users.label" defaultMessage="Users" />, component: <Users />, roles: ['admin'] },
        {
            id: 'series',
            name: <FormattedMessage id="Series.label" defaultMessage="Series" />,
            component: <Series />,
            roles: ['admin']
        },
        { id: 'colors', name: <FormattedMessage id="Colors.label" defaultMessage="Colors" />, component: <Colors />, roles: ['admin'] },
        { id: 'catalogs', name: <FormattedMessage id="Catalogs.label" defaultMessage="Catalogs" />, component: <Catalogs />, roles: ['admin'] },
        { id: 'documents', name: <FormattedMessage id="Documents.label" defaultMessage="Documents" />, component: <Documents />, roles: ['admin', 'customer'] },
        { id: 'products', name: <FormattedMessage id="Products.label" defaultMessage="Products" />, component: <Products />, roles: ['admin'] },
        { id: 'contacts', name: <FormattedMessage id="Contacts.label" defaultMessage="Contacts" />, component: <Contacts />, roles: ['admin'] },
        { id: 'orders', name: <FormattedMessage id="Orders.label" defaultMessage="Orders" />, component: <Orders />, roles: ['admin', 'customer'] },
        { id: 'quotations', name: <FormattedMessage id="Quotations.label" defaultMessage="Quotations" />, component: <Quotations />, roles: ['admin', 'customer'] },
    ].filter((element) => element.roles.includes(panelUser.type))

    return (
        <Drawer className={classes.drawer} classes={{ paper: classes.drawerPaper }} variant="permanent" anchor="left">

            <Link to="/">
                <Box m={1} p={1} bgcolor="white" borderRadius={6} boxShadow={3}>
                    <img src={Logo} width="100%" height="50" alt={Logo} />
                </Box>
            </Link>
            {/** Menu options */}
            <List>
                {fullMenu.map(option => (
                    <ListItem key={option.id} button selected={option.id === currentBody.id} onClick={action => onSelectedMenu(action, option)}>
                        <ListItemText primary={option.name} />
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
}

const TransitionMobileMenu = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// AppMenuMobile is the menu of the mobile app
function AppMenuMobile({ openMobileMenu, setOpenMobileMenu, currentBody, onSelectedMenu }) {

    const theme = useTheme()
    const classes = useStyles()
    const { panelUser } = useContext(PanelUserContext)

    const inMobile = useMediaQuery(theme.breakpoints.down('md'))

    const fullMenu = [
        { id: 'dashboard', name: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />, component: <Dashboard />, roles: ['admin', 'customer'] },
        { id: 'users', name: <FormattedMessage id="Users.label" defaultMessage="Users" />, component: <Users />, roles: ['admin'] },
        {
            id: 'series',
            name: <FormattedMessage id="Series.label" defaultMessage="Series" />,
            component: <Series />,
            roles: ['admin']
        },
        { id: 'colors', name: <FormattedMessage id="Colors.label" defaultMessage="Colors" />, component: <Colors />, roles: ['admin'] },
        { id: 'catalogs', name: <FormattedMessage id="Catalogs.label" defaultMessage="Catalogs" />, component: <Catalogs />, roles: ['admin'] },
        { id: 'documents', name: <FormattedMessage id="Documents.label" defaultMessage="Documents" />, component: <Documents />, roles: ['admin', 'customer'] },
        { id: 'products', name: <FormattedMessage id="Products.label" defaultMessage="Products" />, component: <Products />, roles: ['admin'] },
        { id: 'contacts', name: <FormattedMessage id="Contacts.label" defaultMessage="Contacts" />, component: <Contacts />, roles: ['admin'] },
        { id: 'orders', name: <FormattedMessage id="Orders.label" defaultMessage="Orders" />, component: <Orders />, roles: ['admin', 'customer'] },
        { id: 'quotations', name: <FormattedMessage id="Quotations.label" defaultMessage="Quotations" />, component: <Quotations />, roles: ['admin', 'customer'] },
    ].filter((element) => element.roles.includes(panelUser.type))

    const handleClose = () => {
        setOpenMobileMenu(false)
    }

    return (
        <Dialog open={openMobileMenu && inMobile} fullScreen onClose={handleClose} TransitionComponent={TransitionMobileMenu}>
            <AppBar className={classes.appBar}>
                <Box display="flex" alignItems="center">
                    <IconButton style={{ color: 'white' }} onClick={handleClose} aria-label="close">
                        <IconClose />
                    </IconButton>
                    <Typography variant="h6" >
                        ALUMACE
                </Typography>
                </Box>
            </AppBar>
            <div className={classes.toolbar} />
            <List>
                {fullMenu.map(option => (
                    <ListItem key={option.id} button selected={option.id === currentBody.id} onClick={action => onSelectedMenu(action, option)}>
                        <ListItemText primary={option.name} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    )
}

//AppBody is the body of the app
function AppBody({ currentBody }) {
    // ─── CONETXTS ───────────────────────────────────────────────────────────────────
    const classes = useStyles()

    // ─── STATES ─────────────────────────────────────────────────────────────────────

    // ─── FUNCTIONS ──────────────────────────────────────────────────────────────────

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            { currentBody.component}
        </main>
    )
}

function Home() {


    // ─── CONTEXTS ───────────────────────────────────────────────────────────────────
    const theme = useTheme()
    const classes = useStyles()
    const { Logout } = useContext(UtilitiesContext)
    const inMobile = useMediaQuery(theme.breakpoints.down('md'))

    // ─── STATES ─────────────────────────────────────────────────────────────────────
    const [page, setPage] = useState('loading')
    const [currentBody, setCurrentBody] = useState({
        id: 'dashboard', name: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />, component: <Dashboard />, roles: ['admin', 'customer']
    })

    const [panelUser, setPanelUser] = useState(gvUserConnected)
    const [users, setUsers] = useState([])
    const [series, setSeries] = useState([])
    const [colors, setColors] = useState([])
    const [catalogs, setCatalogs] = useState([])
    const [documents, setDocuments] = useState([])
    const [products, setProducts] = useState([])
    const [contacts, setContacts] = useState([])
    const [orders, setOrders] = useState([])
    const [quotations, setQuotations] = useState([])

    const [openMobileMenu, setOpenMobileMenu] = useState(false)

    document.title = projectName + ` - ${langMessages.messages?.[panelUser.lang]?.['Website.Menu.Panel'] || 'Panel'}`

    // ─── FUNCTIONS ──────────────────────────────────────────────────────────────────

    const handleSelectedMenu = (_, option) => {
        setCurrentBody(option)
        setOpenMobileMenu(false)
    }

    const openSettings = () => {
        setCurrentBody({ id: 'settings', name: 'Settings', component: <Settings />, roles: ['admin'] })
    }

    // effects for checking token
    useEffect(() => {

        let theUser
        try {
            theUser = JSON.parse(localStorage.getItem('user'))
            if (theUser.email.trim() === '') {
                Logout()
                return
            }
        } catch (_) {
            Logout()
            return
        }
        setGvUserConnected(theUser)

        const checkToken = () => {
            axiosSiteData.get(`/users/refresh-token/${theUser.email}`, axiosSiteDataConfig)
                .then(response => {

                    setGvUserConnected({
                        ...theUser,
                        token: response.data.result
                    })
                    setPanelUser({ ...gvUserConnected, token: '' })
                    setPage('default')
                })
                .catch(_ => {
                    Logout()
                })
        }
        checkToken()

        let ticker = setInterval(() => {
            checkToken()
        }, 480000)

        // cancel timer
        return () => {
            clearInterval(ticker)
        }

    }, [Logout])

    return (

        <PanelUserContext.Provider value={{ panelUser, setPanelUser }}>
            <UsersContext.Provider value={{ users, setUsers }}>
                <SeriesContext.Provider value={{ series, setSeries }}>
                    <ColorsContext.Provider value={{ colors, setColors }}>
                        <CatalogsContext.Provider value={{ catalogs, setCatalogs }}>
                            <DocumentsContext.Provider value={{ documents, setDocuments }}>
                                <ProductsContext.Provider value={{ products, setProducts }}>
                                    <ContactsContext.Provider value={{ contacts, setContacts }}>
                                        <OrdersContext.Provider value={{ orders, setOrders }}>
                                            <QuotationsContext.Provider value={{ quotations, setQuotations }} >
                                                {page === 'loading' && <Splash />}
                                                {page === 'default' &&
                                                    <div style={{ display: 'flex', flexGrow: 1, backgroundColor: '#DD5246' }}>
                                                        <CssBaseline />

                                                        {/** Menu */}
                                                        <AppMenuMobile openMobileMenu={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} currentBody={currentBody} onSelectedMenu={handleSelectedMenu} />
                                                        {!inMobile && <AppMenu currentBody={currentBody} onSelectedMenu={handleSelectedMenu} />}


                                                        {/** AppBar */}
                                                        <AppBar position="fixed" className={classes.appBar}>
                                                            <Toolbar>
                                                                {/** Mobile Menu */}
                                                                {inMobile &&
                                                                    <IconButton style={{ marginRight: 'auto', color: 'white' }} onClick={_ => setOpenMobileMenu(true)}>
                                                                        <IconMenu />
                                                                    </IconButton>
                                                                }
                                                                {/** Title */}
                                                                {!inMobile && <Typography variant="h5" style={{ flexGrow: 1, color: 'white', fontWeight: 'bold' }}>
                                                                    ALUMACE
                                                            </Typography>}
                                                                {/** Switch Theme */}
                                                                {/* <Tooltip title="Toggle Light/Dark Theme">
                                                                <IconButton style={{ color: 'white' }}>
                                                                    {panelUser.type === 'light'
                                                                        ?
                                                                        <BrightnessLowTwoToneIcon />
                                                                        :
                                                                        <BrightnessMediumTwoToneIcon />
                                                                    }
                                                                </IconButton>
                                                            </Tooltip> */}
                                                                {/** Settings */}
                                                                {panelUser.type === 'admin' &&
                                                                    <Tooltip title="Open settings">
                                                                        <IconButton onClick={() => openSettings()} style={{
                                                                            marginLeft: theme.spacing(1),
                                                                            color: 'white'
                                                                        }}><IconSettings /></IconButton>
                                                                    </Tooltip>}

                                                                {/** Logout */}
                                                                <Tooltip title="Logout">
                                                                    <IconButton onClick={_ => Logout()} style={{
                                                                        marginLeft: theme.spacing(1),
                                                                        color: 'white'
                                                                    }}><IconLogout /></IconButton>
                                                                </Tooltip>
                                                                {/** User name */}
                                                                <Typography variant="h6" style={{
                                                                    marginLeft: theme.spacing(1),
                                                                    color: 'white'
                                                                }}>{panelUser.name}</Typography>
                                                            </Toolbar>
                                                        </AppBar>

                                                        {/** AppBody */}
                                                        <AppBody currentBody={currentBody} />
                                                    </div>}
                                            </QuotationsContext.Provider>
                                        </OrdersContext.Provider>
                                    </ContactsContext.Provider>
                                </ProductsContext.Provider>
                            </DocumentsContext.Provider>
                        </CatalogsContext.Provider>
                    </ColorsContext.Provider>
                </SeriesContext.Provider>
            </UsersContext.Provider>
        </PanelUserContext.Provider>


    )
}

export default Home
