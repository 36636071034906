import React, { useEffect, useState, useContext, Fragment } from 'react'
import { Box, Button, DialogContentText, TextField } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { CatalogsContext } from '../../../contexts'
import { axiosSiteData, axiosSiteDataConfig, GetCatalogLink, getFileExtension } from '../../../variables'
import Dialoger from '../../Components/dialoger'
import DataTable from "../../Components/DataTable"
import ContentForm from './ContentFormCustom'

import { v4 as uuidv4 } from 'uuid'

//
// ─── ICONS ──────────────────────────────────────────────────────────────────────
import IconAdd from '@material-ui/icons/AddTwoTone'
import IconSearch from '@material-ui/icons/SearchTwoTone'

// API config

let searchTimeout = 0

// Catalogs module
export default function Catalogs() {
    const [content, setContent] = useState('list')      // list | add | edit | delete
    const [selectedRecord, setSelectedRecord] = useState({})
    const [rendered, setRendered] = useState([])

    const { setCatalogs } = useContext(CatalogsContext)

    useEffect(() => {
        axiosSiteData.get(`/catalogs/${(new Date()).toJSON()}`)
            .then(response => {
                setCatalogs(response.data)
                setRendered(response.data.map((aRecord) => (
                    {
                        ...aRecord,
                        _Link: <a href={GetCatalogLink(aRecord._Link)} target="_blank" rel="noopener noreferrer">Download</a>
                    }
                )))
            })
    }, [setCatalogs, content])

    return (
        <Box m={3} p={1} flexGrow={1} display="flex" flexDirection="column">
            {/** Conditional list of UI to render */}
            {(content === 'list' || content === 'delete') &&
                <Listing setSelectedRecord={setSelectedRecord} setContent={setContent} rendered={rendered} setRendered={setRendered} />}
            {(content === 'add') && <Adding setContent={setContent} />}
            {(content === 'edit') && <Editing selectedRecord={selectedRecord} setContent={setContent} />}
            {content === 'delete' && <Deleting selectedRecord={selectedRecord} setContent={setContent} />}
        </Box>
    )
}

// List catalogs
function Listing({ setSelectedRecord, setContent, rendered, setRendered }) {

    // ─── CONTEXTS ───────────────────────────────────────────────────────────────────
    const { catalogs, setCatalogs } = useContext(CatalogsContext)

    // ─── STATES ─────────────────────────────────────────────────────────────────────
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])

    // ─── Table Columns ───────────────────────────────────────────────────────────────────
    let columns = []
    columns = [
        { id: '_Name', numeric: false, label: <FormattedMessage id="Catalogs.Columns._Name" defaultMessage="Name" /> },
        { id: '_Link', numeric: false, label: <FormattedMessage id="Catalogs.Columns._Link" defaultMessage="Link" /> },
        { id: 'actions', numeric: false, label: <FormattedMessage id="TableActions" defaultMessage="Actions" /> }
    ]

    // ─── SEARCH ACTION ──────────────────────────────────────────────────────────────
    const searchAction = (toSearch) => {

        clearTimeout(searchTimeout)
        toSearch = toSearch.toLowerCase()
        setSearch(toSearch)
        searchTimeout = setTimeout(() => {
            if (toSearch.trim() === "") {
                axiosSiteData.get(`/catalogs/${(new Date()).toJSON()}`, axiosSiteDataConfig)
                    .then(response => {
                        setCatalogs(response.data)
                        setRendered(response.data.map((aRecord) => (
                            {
                                ...aRecord,
                                _Link: <a href={GetCatalogLink(aRecord._Link)} target="_blank" rel="noopener noreferrer">Download</a>
                            }
                        )))
                        setSearchResults([])
                    })

                return
            }

            axiosSiteData.get(`/catalogs/search/${toSearch}`, axiosSiteDataConfig)
                .then(response => {
                    setSearchResults(response.data.map((aRecord) => (
                        {
                            ...aRecord,
                            _Link: <a href={GetCatalogLink(aRecord._Link)} target="_blank" rel="noopener noreferrer">Download</a>
                        }
                    )))
                })
        }, 200)
    }

    // ─── Edit Action ───────────────────────────────────────────────────────────────────
    const editAction = (row) => {
        setSelectedRecord(catalogs.filter(aRecord => {
            return aRecord._id === row._id
        })[0])
        setContent('edit')
    }

    // ─── Delete Action ───────────────────────────────────────────────────────────────────
    const deleteAction = (row) => {
        setSelectedRecord(row)
        setContent('delete')
    }

    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width={1} mb={2}>
                {/** Search bar */}
                <TextField
                    value={search}
                    onChange={event => searchAction(event.target.value)}
                    style={{ width: "40%" }}
                    variant="outlined"
                    label={<FormattedMessage id="TableSearchBar" defaultMessage="Search something ..." />}
                    InputProps={{
                        endAdornment: (
                            <IconSearch />
                        ),
                    }}
                />

                {/** Button Add */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setContent('add')}
                >
                    <IconAdd /> <FormattedMessage id="TableAdd" defaultMessage="ADD" />
                </Button>
            </Box>

            {/** DataTable for Catalogs */}
            <DataTable
                columns={columns}
                rows={search === '' ? rendered : searchResults}
                order="asc"
                orderBy="_Code"
                editAction={editAction}
                deleteAction={deleteAction}
            />
        </Box>
    )
}

// Add catalog
function Adding({ setContent }) {

    const handleSubmit = (record, file) => {

        // We make sure some fields aren't empty
        if (record._Name.trim() === '' || file === null) {
            return false
        }

        // We upload the file
        const reader = new FileReader()
        reader.onabort = () => { return }
        reader.onerror = () => { return }
        reader.onload = () => {

            // We upload file
            axiosSiteData.post(`/files/catalogs/catalog-${uuidv4()}.${getFileExtension(file.name)}`, reader.result, axiosSiteDataConfig)
                .then((result) => {

                    // We now add the catalog with the file link
                    const theRecord = { ...record, _Link: result.data.result }
                    axiosSiteData.post(`/catalogs`, theRecord, axiosSiteDataConfig)
                        .then(() => {
                            setContent('list')
                        })
                        .catch(errors => console.log(errors))
                })
                .catch(errors => console.log(errors))
        }
        reader.readAsArrayBuffer(file)
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={{}} content="add" setContent={setContent} handleSubmit={handleSubmit} />
        </Fragment>
    )
}

// Update catalog
function Editing({ selectedRecord, setContent }) {

    const handleSubmit = (record, file) => {

        // We make sure some fields aren't empty
        if (record._Name.trim() === '' || file === null) {
            return false
        }

        // We upload the file
        const reader = new FileReader()
        reader.onabort = () => { return }
        reader.onerror = () => { return }
        reader.onload = () => {

            // We upload file
            axiosSiteData.post(`/files/catalogs/catalog-${uuidv4()}.${getFileExtension(file.name)}`, reader.result, axiosSiteDataConfig)
                .then((result) => {

                    // We now add the catalog with the file link
                    const theRecord = { ...record, _Link: result.data.result }
                    axiosSiteData.put(`/catalogs/${record._id}`, theRecord, axiosSiteDataConfig)
                        .then(() => {
                            setContent('list')
                        })
                        .catch(errors => console.log(errors))
                })
                .catch(errors => console.log(errors))
        }
        reader.readAsArrayBuffer(file)
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={selectedRecord} content="edit" setContent={setContent}
                handleSubmit={handleSubmit} />
        </Fragment>
    )
}

// Delete catalog
function Deleting({ selectedRecord, setContent }) {

    const handleDelete = (setOpen) => {

        axiosSiteData.delete(`/catalogs/${selectedRecord._id}`, axiosSiteDataConfig)
            .then(() => {
                setOpen(false)
                setContent('list')
            })
            .catch(errors => console.log(errors))
    }

    return (
        <Dialoger
            title={`Catalog deletion`}
            content={
                <DialogContentText>Would you really like to delete this catalog ?</DialogContentText>
            }
            onClose={() => {
                setContent("list")
            }}
            onAction={handleDelete}
        />
    )
}
