import React, { useEffect, useState, useContext, Fragment } from 'react'
import {
    Box, Button, DialogContentText, TextField, Grid,
    FormLabel, RadioGroup, FormControlLabel, Radio, FormControl
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { OrdersContext, PanelUserContext } from '../../../contexts'
import { axiosSiteData, axiosSiteDataConfig, gvUserConnected } from '../../../variables'
import Dialoger from '../../Components/dialoger'
import DataTable from "./DataTable"
import ContentForm from './ContentFormCustom'
import ContentView from './ContentView'

//
// ─── ICONS ──────────────────────────────────────────────────────────────────────
import IconAdd from '@material-ui/icons/AddTwoTone'
import IconSearch from '@material-ui/icons/SearchTwoTone'

// API config

let searchTimeout = 0

// Orders module
export default function Orders() {

    const { panelUser } = useContext(PanelUserContext)

    const [content, setContent] = useState('list')      // list | add | edit | view | delete
    const [selectedRecord, setSelectedRecord] = useState({})
    const [rendered, setRendered] = useState([])
    const [filter, setFilter] = useState({ status: 'Submetida', exported: panelUser.type === 'admin' ? false : 'All' })
    const [hasMore, setHasMore] = useState(false)
    const [noMore, setNoMore] = useState(false)


    const { setOrders } = useContext(OrdersContext)

    useEffect(() => {
        axiosSiteData.get(`/orders`, axiosSiteDataConfig)
            .then(response => {

                if (response.data.length === 100) {
                    setNoMore(false)
                } else {
                    setHasMore(true)
                }

                setOrders(response.data)
                setRendered(response.data.filter(elem => (
                    (filter.status === 'All' ? true : elem._Status === filter.status) && (filter.exported === 'All' ? true : elem._Exported === filter.exported)
                ))
                    .map((aRecord) => (
                        {
                            ...aRecord,
                            _CreatedAt: new Date(aRecord._CreatedAt).toLocaleString()
                        }
                    )))
            })
    }, [setOrders, content, filter])

    return (
        <Box m={3} p={1} flexGrow={1} display="flex" flexDirection="column">
            {/** Conditional list of UI to render */}
            {(content === 'list' || content === 'delete') &&
                <Listing setSelectedRecord={setSelectedRecord} setContent={setContent} rendered={rendered} setRendered={setRendered}
                    filter={filter} setFilter={setFilter} hasMore={hasMore} setHasMore={setHasMore} setNoMore={setNoMore} noMore={noMore}
                />}
            {(content === 'add') && <Adding setContent={setContent} />}
            {(content === 'edit') && <Editing selectedRecord={selectedRecord} setContent={setContent} />}
            {(content === 'view') && <Viewing selectedRecord={selectedRecord} setContent={setContent} />}
            {content === 'delete' && <Deleting selectedRecord={selectedRecord} setContent={setContent} />}
        </Box>
    )
}

// List orders
function Listing({ setSelectedRecord, setContent, rendered, setRendered, filter, setFilter, hasMore, setHasMore, setNoMore, noMore }) {

    // ─── CONTEXTS ───────────────────────────────────────────────────────────────────
    const { orders, setOrders } = useContext(OrdersContext)
    const { panelUser } = useContext(PanelUserContext)

    // ─── STATES ─────────────────────────────────────────────────────────────────────
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])


    // ─── Table Columns ───────────────────────────────────────────────────────────────────
    let columns = []
    columns = [
        { id: '_Number', numeric: false, label: "#" },
        { id: '_Reference', numeric: false, label: <FormattedMessage id="Orders.Columns._Reference" defaultMessage="Reference" /> },
        panelUser.type === 'admin' ?
            { id: '_User|_Company', numeric: false, label: <FormattedMessage id="Users.Columns._Company" defaultMessage="Company" /> }
            :
            { id: '_Description', numeric: false, label: <FormattedMessage id="Orders.Columns._Description" defaultMessage="Description" /> },
        { id: '_Status', numeric: false, label: <FormattedMessage id="Orders.Columns._Status" defaultMessage="Status" /> },
        ...panelUser.type === 'admin' ? [{ id: '_Exported', numeric: false, type: 'bool', label: <FormattedMessage id="Orders.Columns._Exported" defaultMessage="Exported" /> }] : [],
        { id: '_CreatedAt', numeric: false, label: <FormattedMessage id="Orders.Columns._CreatedAt" defaultMessage="Created At" /> },
        { id: 'actions', numeric: false, label: <FormattedMessage id="TableActions" defaultMessage="Actions" /> }
    ]

    // ─── SEARCH ACTION ──────────────────────────────────────────────────────────────
    const searchAction = (toSearch) => {

        clearTimeout(searchTimeout)
        toSearch = toSearch.toLowerCase()
        setSearch(toSearch)
        searchTimeout = setTimeout(() => {
            if (toSearch.trim() === "") {
                axiosSiteData.get(`/orders/${(new Date()).toJSON()}`, axiosSiteDataConfig)
                    .then(response => {

                        if (response.data.length === 100) {
                            setNoMore(false)
                        } else {
                            setHasMore(true)
                        }

                        setOrders(response.data)
                        setRendered(response.data.filter(elem => (
                            (filter.status === 'All' ? true : elem._Status === filter.status) && (filter.exported === 'All' ? true : elem._Exported === filter.exported)
                        ))
                            .map((aRecord) => (
                                {
                                    ...aRecord,
                                    _CreatedAt: new Date(aRecord._CreatedAt).toLocaleString()
                                }
                            )))
                        setSearchResults([])
                    })

                return
            }

            axiosSiteData.get(`/orders/search/${toSearch}/${(new Date()).toJSON()}`, axiosSiteDataConfig)
                .then(response => {

                    if (response.data.length === 100) {
                        setNoMore(false)
                    } else {
                        setHasMore(true)
                    }

                    setSearchResults(response.data.map((aRecord) => (
                        {
                            ...aRecord,
                            _CreatedAt: new Date(aRecord._CreatedAt).toLocaleString()
                        }
                    )))
                    /* setFilter({ status: 'All', exported: 'All' }) */
                })
        }, 200)
    }

    const loadMoreAction = () => {

        if (orders.length <= 0) return

        if (search.trim() === "") {
            axiosSiteData.get(`/orders/${orders[orders.length - 1]._CreatedAt}`, axiosSiteDataConfig)
                .then(response => {

                    if (response.data.length === 100) {
                        setNoMore(false)
                    } else {
                        setHasMore(true)
                    }

                    setOrders(orders.concat(response.data))
                    setRendered(rendered.concat(response.data
                        .filter(elem => (
                            (filter.status === 'All' ? true : elem._Status === filter.status) && (filter.exported === 'All' ? true : elem._Exported === filter.exported)
                        ))
                        .map((aRecord) => (
                            {
                                ...aRecord,
                                _CreatedAt: new Date(aRecord._CreatedAt).toLocaleString()
                            }
                        ))))
                })

            return
        }

        if (searchResults.length <= 0) return
        axiosSiteData.get(`/orders/search/${search}/${new Date(searchResults[searchResults.length - 1]._CreatedAt).toJSON()}`, axiosSiteDataConfig)
            .then(response => {

                if (response.data.length === 100) {
                    setNoMore(false)
                } else {
                    setHasMore(true)
                }

                setSearchResults(searchResults.concat(response.data.map((aRecord) => (
                    {
                        ...aRecord,
                        _CreatedAt: new Date(aRecord._CreatedAt).toLocaleString()
                    }
                ))))
                /* setFilter({ status: 'All', exported: 'All' }) */
            })

    }

    //
    // ─── FILTER ACTION ──────────────────────────────────────────────────────────────
    const filterAction = (myFilter) => {

        let myExported = 'All'
        switch (myFilter.exported) {
            case 'true':
            case true:
                myExported = true
                break

            case 'false':
            case false:
                myExported = false
                break
            default:
                break;
        }

        setFilter({ ...myFilter, exported: myExported })
    }

    // ─── Edit Action ───────────────────────────────────────────────────────────────────
    const editAction = (row) => {
        setSelectedRecord(orders.filter(aRecord => {
            return aRecord._id === row._id
        })[0])
        setContent(row._Status !== 'Rascunho' ? 'view' : 'edit')
    }

    // ─── Delete Action ───────────────────────────────────────────────────────────────────
    const deleteAction = (row) => {
        setSelectedRecord(row)
        setContent('delete')
    }

    // Handle infinite scroll
    useEffect(() => {

        if (search.trim() === "") {
            if (rendered.length > 0 && (rendered.length % 100) === 0 && !noMore) {
                setHasMore(true)
            } else {
                setHasMore(false)
            }
            return
        }

        if (searchResults.length > 0 && (searchResults.length % 100) === 0 && !noMore) {
            setHasMore(true)
        } else {
            setHasMore(false)
        }
    }, [rendered, noMore, setHasMore, searchResults, search])

    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width={1} mb={2}>
                {/** Search bar */}
                <TextField
                    value={search}
                    onChange={event => searchAction(event.target.value)}
                    style={{ width: "40%" }}
                    variant="outlined"
                    label={<FormattedMessage id="TableSearchBar" defaultMessage="Search something ..." />}
                    InputProps={{
                        endAdornment: (
                            <IconSearch />
                        ),
                    }}
                />

                {/** Button Add */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setContent('add')}
                >
                    <IconAdd /> <FormattedMessage id="TableAdd" defaultMessage="ADD" />
                </Button>
            </Box>

            {/** Filters */}
            <Grid container spacing={2}>
                {/** Status filter */}
                <Grid item >
                    <FormControl component="fieldset">
                        <FormLabel component="legend"><FormattedMessage id="Orders.Filter.Status" defaultMessage="Filter by status" /></FormLabel>
                        <RadioGroup row aria-label="status" name="status" value={filter.status} onChange={event => filterAction({ ...filter, status: event.target.value })}>
                            <FormControlLabel value="All" control={<Radio />} label={<FormattedMessage id="All" defaultMessage="All" />} />
                            <FormControlLabel value="Rascunho" control={<Radio />} label="Rascunho" />
                            <FormControlLabel value="Submetida" control={<Radio />} label="Submetida" />
                            <FormControlLabel value="Terminado" control={<Radio />} label="Terminado" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {/** Exported filter */}
                {panelUser.type === 'admin' &&
                    <Grid item >
                        <FormControl component="fieldset">
                            <FormLabel component="legend"><FormattedMessage id="Orders.Filter.Exported" defaultMessage="Filter by exported" /></FormLabel>
                            <RadioGroup row aria-label="status" name="status" value={filter.exported}
                                onChange={event => filterAction({ ...filter, exported: event.target.value })}
                            >
                                <FormControlLabel value="All" control={<Radio />} label={<FormattedMessage id="All" defaultMessage="All" />} />
                                <FormControlLabel value={true} control={<Radio />} label={<FormattedMessage id="Orders.Columns._Exported" defaultMessage="Exported" />} />
                                <FormControlLabel value={false} control={<Radio />} label={<FormattedMessage id="Orders.Filter.NotExported" defaultMessage="Not exported" />} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>}
            </Grid>

            {/** DataTable for Orders */}
            <DataTable
                columns={columns}
                rows={search === '' ? rendered : searchResults}
                order="asc"
                orderBy="_Code"
                editAction={editAction}
                deleteAction={deleteAction}
                loadMoreFunc={loadMoreAction} hasMore={hasMore}
            />
        </Box>
    )
}

// Add order
function Adding({ setContent }) {

    const handleSubmit = (record) => {

        // We make sure some fields aren't empty
        if (record._Reference.trim() === '' || record._Description.trim() === '' || record._Status.trim() === '') {
            return false
        }

        if (record._Observations.trim() === '' && record._Items.length === 0) {
            return false
        }

        axiosSiteData.post(`/orders`, {
            ...record, _User: {
                _Name: gvUserConnected.name, _Email: gvUserConnected.email, _Company: gvUserConnected.company,
                _Phone: gvUserConnected.phone, _AccountID: gvUserConnected.accountID
            }
        }, axiosSiteDataConfig)
            .then(() => {
                setContent('list')
            })
            .catch(errors => console.log(errors))
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={{
                _CreatedAt: new Date().toLocaleDateString(),
                _Reference: '',
                _Description: '',
                _Status: "Rascunho",
                _Exported: false,
                _Items: [],
                _User: {
                    _Name: '', _Email: '', _Company: '', _Phone: '', _AccountID: ''
                },
                _Observations: ''
            }} content="add" setContent={setContent} handleSubmit={handleSubmit} />
        </Fragment>
    )
}

// Update order
function Editing({ selectedRecord, setContent }) {

    const handleSubmit = (record) => {

        if (record._Reference.trim() === '' || record._Description.trim() === '' || record._Status.trim() === '') {
            return false
        }

        if (record._Observations.trim() === '' && record._Items.length === 0) {
            return false
        }

        axiosSiteData.put(`/orders/${record._id}`, {
            ...record, _User: {
                _Name: gvUserConnected.name, _Email: gvUserConnected.email, _Company: gvUserConnected.company,
                _Phone: gvUserConnected.phone, _AccountID: gvUserConnected.accountID
            }
        }, axiosSiteDataConfig)
            .then(_ => setContent('list'))
            .catch(errors => console.log(errors))
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={selectedRecord} content="edit" setContent={setContent}
                handleSubmit={handleSubmit} />
        </Fragment>
    )
}

// View order
function Viewing({ selectedRecord, setContent }) {

    return (
        <Fragment>
            <ContentView selectedRecord={selectedRecord} setContent={setContent} />
        </Fragment>
    )
}

// Delete order
function Deleting({ selectedRecord, setContent }) {

    const handleDelete = (setOpen) => {

        axiosSiteData.delete(`/orders/${selectedRecord._id}`, axiosSiteDataConfig)
            .then(() => {
                setOpen(false)
                setContent('list')
            })
            .catch(errors => console.log(errors))
    }

    return (
        <Dialoger
            title={`Order deletion`}
            content={
                <DialogContentText>Would you really like to delete this order ?</DialogContentText>
            }
            onClose={() => {
                setContent("list")
            }}
            onAction={handleDelete}
        />
    )
}
