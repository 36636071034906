import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { TextField, InputAdornment, IconButton, Popper, Fade, Box, ListItem, useTheme, ClickAwayListener } from '@material-ui/core'

// Icons
import IconClosed from '@material-ui/icons/ArrowDropDown'
import IconOpened from '@material-ui/icons/ArrowDropUp'

// Libs
import { FixedSizeList } from 'react-window'

function ItemRow({ index, data, style }) {

    return (
        <ListItem
            button style={style}
            onClick={_ => data.handleSelectedItem(data.data[index])}
        >
            {data.itemRender(data.data[index])}
        </ListItem>
    )
}

function Listing({ items, opened, anchorEl, itemRender, itemHeight, listHeight, itemKey, itemsWidth, handleSelectedItem, selected }) {

    const theme = useTheme()

    return (
        <Popper open={opened} anchorEl={anchorEl} placement="bottom-start" transition style={{ zIndex: 99 }}>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>

                    {/** Items */}
                    <Box boxShadow={3} bgcolor={theme.palette.background.paper}>
                            <FixedSizeList
                                height={listHeight}
                                itemCount={items.length}
                                itemSize={itemHeight}
                                width={itemsWidth}
                                itemKey={(index, data) => data.data[index][itemKey]}
                                itemData={{ data: items, itemRender: itemRender, handleSelectedItem }}
                            >
                                {ItemRow}
                            </FixedSizeList>
                            {/* <List>
                                {items.map(item => (
                                    <ListItem button>
                                        {itemRender(item)}
                                    </ListItem>
                                ))}
                            </List> */}
                        </Box>
                </Fade>
            )}
        </Popper>
    )
}

//
// ─── AUTOCOMPLETE ───────────────────────────────────────────────────────────────
function Autocomplete(
    {
        items, itemRender,
        itemHeight = 40, listHeight = 200, itemsWidth = 300,
        itemKey = "_id",
        variant = "outlined", label, margin="normal", style, fullWidth,
        setSelecetdItemLabel, onSearch, value, setValue
    }) {

    //
    // ─── STATES ─────────────────────────────────────────────────────────────────────
    const [opened, setOpened] = useState(false)
    // const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [selected, setSelected] = useState(null)

    //
    // ─── REFS ───────────────────────────────────────────────────────────────────────
    const anchorRef = useRef(null)

    //
    // ─── FUNCTIONS ──────────────────────────────────────────────────────────────────
    const handleItemsOpenStatus = () => {

        setValue('')
        setSearchResults([])
        setOpened(!opened)
    }

    const handleClickedAway = () => {
        setOpened(false)
    }

    const handleSearch = (event) => {

        const searchedValue = event.target.value
        setValue(searchedValue)
        setSelected(null)

        if (searchedValue.trim() === '') {
            setOpened(false)
            setSearchResults([])
            return
        }


        setOpened(true)
        setSearchResults(onSearch(searchedValue))
    }

    const handleSelectedItem = (item) => {

        setOpened(false)
        setSelected(item)
        setSelecetdItemLabel(item)
    }



    return (
            <ClickAwayListener onClickAway={handleClickedAway}>
                <Box>
                    <TextField
                        ref={anchorRef}
                        variant={variant} fullWidth={fullWidth}
                        label={label}
                        margin={margin} style={style}
                        value={value}
                        onChange={handleSearch}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleItemsOpenStatus} size={margin === 'normal' ? 'medium' : 'small'}>
                                        {opened ? <IconOpened /> : <IconClosed />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
        
                    />
                    <Listing
                        items={value.trim() === '' ? items : searchResults} opened={opened} handleSelectedItem={handleSelectedItem}
                        selected={selected}
                        itemRender={itemRender}
                        itemHeight={itemHeight} listHeight={listHeight} itemsWidth={itemsWidth}
                        itemKey={itemKey}
                        anchorEl={anchorRef.current}
                    />
                </Box>
            </ClickAwayListener>
    )
}

Autocomplete.propTypes = {
    // id: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    setSelecetdItemLabel: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    itemRender: PropTypes.func.isRequired,
    variant: PropTypes.string,
    label: PropTypes.node,
    margin: PropTypes.string
}

export default Autocomplete