import React, {useEffect, useState, useContext, Fragment} from 'react'
import { Box, Button, DialogContentText, TextField  } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import {SeriesContext} from '../../../contexts'
import { axiosSiteData, axiosSiteDataConfig } from '../../../variables'
import Dialoger from '../../Components/dialoger'
import DataTable from "../../Components/DataTable"
import ContentForm from './ContentForm'

//
// ─── ICONS ──────────────────────────────────────────────────────────────────────
import IconAdd from '@material-ui/icons/AddTwoTone'
import IconSearch from '@material-ui/icons/SearchTwoTone'

// API config

let searchTimeout = 0

// Series module
export default function Series() {
    const [content, setContent] = useState('list')      // list | add | edit | delete
    const [selectedRecord, setSelectedRecord] = useState({})

    const { setSeries } = useContext(SeriesContext)

    useEffect(() => {
        axiosSiteData.get(`/series/${(new Date()).toJSON()}`, axiosSiteDataConfig)
            .then(response => {
                setSeries(response.data)
            })
    }, [setSeries, content])

    return (
        <Box m={3} p={1} flexGrow={1} display="flex" flexDirection="column">
            {/** Conditional list of UI to render */}
            {(content === 'list' || content === 'delete') &&
            <Listing setSelectedRecord={setSelectedRecord} setContent={setContent}/>}
            {(content === 'add') && <Adding setContent={setContent}/>}
            {(content === 'edit') && <Editing selectedRecord={selectedRecord} setContent={setContent}/>}
            {content === 'delete' && <Deleting selectedRecord={selectedRecord} setContent={setContent}/>}
        </Box>
    )
}

// List series
function Listing({setSelectedRecord, setContent}) {

    // ─── CONTEXTS ───────────────────────────────────────────────────────────────────
    const { series, setSeries } = useContext(SeriesContext)

    // ─── STATES ─────────────────────────────────────────────────────────────────────
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])

    // ─── Table Columns ───────────────────────────────────────────────────────────────────
    let columns = []
    columns = [
		{id: '_Label', numeric: false, label: <FormattedMessage id="Series.Columns._Label" defaultMessage="Label" />},
		{id: 'actions', numeric: false, label: <FormattedMessage id="TableActions" defaultMessage="Actions" />}
	]

    // ─── SEARCH ACTION ──────────────────────────────────────────────────────────────
    const searchAction = (toSearch) => {

        clearTimeout(searchTimeout)
        toSearch = toSearch.toLowerCase()
        setSearch(toSearch)
        searchTimeout = setTimeout(() => {
            if (toSearch.trim() === "") {
                axiosSiteData.get(`/series/${(new Date()).toJSON()}`, axiosSiteDataConfig)
                    .then(response => {
                        setSeries(response.data)
                        setSearchResults([])
                    })
    
                return
            }
    
            axiosSiteData.get(`/series/search/${toSearch}`, axiosSiteDataConfig)
                .then(response => {
                    setSearchResults(response.data)
                })
        }, 200)
    }

    // ─── Edit Action ───────────────────────────────────────────────────────────────────
    const editAction = (row) => {
        setSelectedRecord(row)
        setContent('edit')
    }

    // ─── Delete Action ───────────────────────────────────────────────────────────────────
    const deleteAction = (row) => {
        setSelectedRecord(row)
        setContent('delete')
    }

    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width={1} mb={2}>
                {/** Search bar */}
                <TextField
                    value={search}
                    onChange={event => searchAction(event.target.value)}
                    style={{ width: "40%" }}
                    variant="outlined"
                    label={<FormattedMessage id="TableSearchBar" defaultMessage="Search something ..." />}
                    InputProps={{
                        endAdornment: (
                            <IconSearch />
                        ),
                    }}
                />

                {/** Button Add */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setContent('add')}
                >
                    <IconAdd /> <FormattedMessage id="TableAdd" defaultMessage="ADD" />
                </Button>
            </Box>

            {/** DataTable for Series */}
            <DataTable
                columns={columns}
                rows={search === '' ? series : searchResults}
                order="asc"
                orderBy="_Code"
                editAction={editAction}
                deleteAction={deleteAction}
            />
        </Box>
    )
}

// Add serie
function Adding({setContent}) {

    const handleSubmit = (record) => {

        // We make sure some fields aren't empty
        if (record._Label.trim() === '') {
            return false
        }

        axiosSiteData.post(`/series`, record, axiosSiteDataConfig)
            .then(() => {
                setContent('list')
            })
            .catch(errors => console.log(errors))
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={{}} content="add" setContent={setContent} handleSubmit={handleSubmit}/>
        </Fragment>
    )
}

// Update serie
function Editing({selectedRecord, setContent}) {

    const handleSubmit = (record) => {

        if (record._Label.trim() === '') {
            return false
        }

        axiosSiteData.put(`/series/${record._id}`, record, axiosSiteDataConfig)
            .then(_ => setContent('list'))
            .catch(errors => console.log(errors))
    }

    return (
        <Fragment>
            <ContentForm selectedRecord={selectedRecord} content="edit" setContent={setContent}
                         handleSubmit={handleSubmit}/>
        </Fragment>
    )
}

// Delete serie
function Deleting({selectedRecord, setContent}) {

    const handleDelete = (setOpen) => {

        axiosSiteData.delete(`/series/${selectedRecord._id}`, axiosSiteDataConfig)
            .then(() => {
                setOpen(false)
                setContent('list')
            })
            .catch(errors => console.log(errors))
    }

    return (
        <Dialoger
            title={`Serie deletion`}
            content={
                <DialogContentText>Would you really like to delete this serie ?</DialogContentText>
            }
            onClose={() => {
                setContent("list")
            }}
            onAction={handleDelete}
        />
    )
}
