import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Button, TextField, CardContent, Card, FormControl, FormGroup, Typography, Box, Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'


// Icons
// import IconArrowBackIos from '@material-ui/icons/ArrowBackIosTwoTone'


// Libs
import { FormattedMessage } from 'react-intl'
import { useSnackbar } from 'notistack'

// Components
import Loader from '../../../Components/Loader'

// Pages
import Header from '../Header'
import Footer from '../Footer'

// Views
import Splash from '../../Splash'
import PasswordReset from './PasswordReset'

// Others
import { projectName, langMessages, axiosSiteData, gvUserConnected, setGvUserConnected, axiosSiteDataConfig } from '../../../../variables'
import { UserConnectedContext, UtilitiesContext } from '../../../../contexts'


function Login() {

    const { userConnected } = useContext(UserConnectedContext)
    const { APIError } = useContext(UtilitiesContext)
    document.title = projectName + ` - ${langMessages.messages?.[userConnected.lang]?.['Website.Login.Title'] || 'Reserved Area'}`


    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar()

    // States
    const [page, setPage] = useState('loading')
    const [record, setRecord] = useState({
        _Email: '', _Password: ''
    })
    const [contentAction, setContentAction] = useState('default')   // default  | pwd-reset
    const [working, setWorking] = useState(false)

    // Send the contact infos
    const handleSubmit = () => {

        // We make sure some fields aren't empty
        if (record._Email.trim() === '' || record._Password.trim() === '') {
            enqueueSnackbar(langMessages.messages?.[userConnected.lang]?.['Err.Login.Empty'] || 'Please specify your credentials', { variant: 'info' })
            return
        }

        setWorking(true)
        axiosSiteData.post(`/users/login`, record)
            .then(response => {

                const theUser = response.data
                setGvUserConnected({
                    ...gvUserConnected,
                    name: theUser._Name, email: theUser._Email, type: theUser._Role, company: theUser._Company,
                    phone: theUser._Phone, accountID: theUser._AccountID,
                    token: theUser._Token
                })
                window.location.pathname = 'panel'
            })
            .catch(errors => {
                setWorking(false)
                APIError(errors, true)
            })
    }

    // effects for checking token
    useEffect(() => {

        const checkToken = () => {

            let theUser
            try {
                theUser = JSON.parse(localStorage.getItem('user'))
                if (theUser.email.trim() === '') {
                    setPage('default')
                    return
                }
            } catch (_) {
                setPage('default')
                return
            }
            setGvUserConnected(theUser)

            // we try refreshing the token
            axiosSiteData.get(`/users/refresh-token/${theUser.email}`, axiosSiteDataConfig)
                .then(response => {

                    setGvUserConnected({
                        ...theUser,
                        token: response.data.result
                    })
                    window.location.pathname = 'panel'
                })
                .catch(_ => {
                    setPage('default')
                })
        }

        checkToken()
    }, [])


    return (
        <Fragment>
            <Header />
            {page === 'loading' && <Splash />}
            {page === 'default' &&
                <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1} py={10} px={4}
                    bgcolor={theme.palette.background.default}
                    style={{ backgroundImage: 'url("https://s3.eu-central-1.wasabisys.com/3cs/Alumace/LoginBG.png")', backgroundSize: 'cover' }}
                >
                    <Grid container justify="center">
                        <Grid item xs={12} md={4} >
                            <Card style={{ padding: theme.spacing(4) }}>
                                {contentAction === 'pwd-reset' &&
                                    <PasswordReset setContentAction={setContentAction} />
                                }
                                {contentAction === 'default' &&
                                    <CardContent>

                                        <Box display="flex" flexDirection="column" >
                                            <Typography variant="h4" style={{ marginBottom: theme.spacing(2) }}>
                                                <FormattedMessage id="Website.Login.Title" defaultMessage="Reserved Area" />
                                            </Typography>
                                        </Box>

                                        {/* Login Form */}
                                        <form onSubmit={_ => handleSubmit()}>

                                            {/** Field Email */}
                                            <FormControl fullWidth margin="normal">
                                                <TextField
                                                    label={<FormattedMessage id="Website.Login.Email" defaultMessage="Email" />}
                                                    id="_Email"
                                                    placeholder={langMessages.messages?.[userConnected.lang]?.['Website.Login.Email'] || 'Email'}
                                                    type="email"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    value={record._Email}
                                                    onChange={event => setRecord({ ...record, _Email: event.target.value })}
                                                />
                                            </FormControl>


                                            {/** Field Password */}
                                            <FormControl fullWidth margin="normal">
                                                <TextField
                                                    label={<FormattedMessage id="Website.Login.Password" defaultMessage="Password" />}
                                                    id="_Password"
                                                    placeholder={langMessages.messages?.[userConnected.lang]?.['Website.Login.Password'] || 'Password'}
                                                    variant="outlined"
                                                    type="password"
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    value={record._Password}
                                                    onChange={event => setRecord({ ...record, _Password: event.target.value })}
                                                    onKeyPress={event => event.key === "Enter" && handleSubmit()}
                                                />
                                            </FormControl>

                                            {/** Password reset button */}
                                            <Button color="secondary" disabled={working}
                                                onClick={_ => setContentAction('pwd-reset')}
                                            >
                                                {working ? <Loader /> : <FormattedMessage id="Website.Login.PasswordReset" defaultMessage="Forgot password ?" />}
                                            </Button>

                                            {/** Button Submit */}
                                            <FormGroup row={true} style={{ marginTop: theme.spacing(6) }}>
                                                <Button size="large" variant="contained" color="primary" disabled={working} fullWidth onClick={() => handleSubmit()}>
                                                    {working ? <Loader /> : <FormattedMessage id="FormLogin" defaultMessage="LOGIN" />}
                                                </Button>
                                            </FormGroup>
                                        </form>
                                    </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </Box>}
            <Footer />
        </Fragment>
    )
}

export default Login