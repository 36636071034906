import React from 'react'
import { Box, Divider, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

// Libs

function Users({ record }) {

    const theme = useTheme()
    const inMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box display="flex" flexDirection="column" bgcolor={theme.palette.background.paper} boxShadow={4} borderRadius={8} p={4} pt={1} mx={inMobile ? 0 : 2} my={2}>
            <Typography variant={inMobile ? 'h6' : 'h4'} color="primary" style={{ marginBottom: theme.spacing(1) }}>
                <FormattedMessage id="Dashboard.Users" defaultMessage="Users" />
            </Typography>
            <Divider variant="fullWidth" />
            <Box height={theme.spacing(2)} />
            
            <Typography variant={inMobile ? 'h2' : 'h1'}>
                {record}
            </Typography>
        </Box>
    )
}

export default Users