import React from 'react'
import { BuilderComponent } from '@builder.io/react'


function BodyBuilder({ bodyEntry }) {
    return (
        <BuilderComponent
            model="pages-body"
            entry={bodyEntry}
        />
    )
}

export default BodyBuilder
