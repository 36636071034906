import React, { useContext, useState, useEffect } from 'react'
import {
	IconButton, Button, TextField, CardContent, Card, FormControl, FormGroup, Typography, Box, Grid,
	TableContainer, Table, TableCell,
	Paper,
	TableHead,
	TableBody,
	TableRow
} from '@material-ui/core'
import MUIAutocomplete from 'Views/Components/Autocomplete'
import { Autocomplete } from '@material-ui/lab'
import { useTheme } from '@material-ui/core/styles'
import Loader from '../../Components/Loader'
import { FormattedMessage } from 'react-intl'
import { ColorsContext, PanelUserContext } from '../../../contexts'
import { axiosSiteData, axiosSiteDataConfig } from '../../../variables'

import IconArrowBackIos from '@material-ui/icons/ArrowBackIosTwoTone'
import IconUpdate from '@material-ui/icons/EditTwoTone'
import IconDelete from '@material-ui/icons/DeleteTwoTone'

import { useSnackbar } from 'notistack'


export default function ContentForm({ selectedRecord, content, setContent, handleSubmit }) {

	// ─── CONTEXTS ───────────────────────────────────────────────────────────────────
	const theme = useTheme()
	const { enqueueSnackbar } = useSnackbar()
	const { panelUser } = useContext(PanelUserContext)

	const [activeProducts, setActiveProducts] = useState([])
	const [itemsAction, setItemsAction] = useState('add')
	const { colors, setColors } = useContext(ColorsContext)

	const [record, setRecord] = useState(selectedRecord)
	const [selectedProduct, setSelectedProduct] = useState(
		{
			_id: '', _Label: '', _Description: '', _Picture: '', _Unit: '',
			_ColorI: false, _ColorM: false, _ColorE: false, _Serie: '',
		})
	const [currentItem, setCurrentItem] = useState({
		_Label: '',
		_Description: '',
		_Picture: '',
		_Unit: '',
		_ColorI: '', _ColorM: '', _ColorE: '',
		_Serie: '',
		_Quantity: ''
	})
	const [loader, setLoader] = useState(false)


	// ─── FUNCTIONS ──────────────────────────────────────────────────────────────────
	const itemRender = (item) => (
		<Box display="flex" flexDirection="column" width={1} height={60}>
			<Box display="flex" flexGrow={1} flexDirection="row" alignItems="center">
				<Typography style={{ flexGrow: 1 }}>{item._Label}</Typography>
				<Typography variant="subtitle2" color="textSecondary"
					style={{
						marginRight: theme.spacing(1),
						justifySelf: 'flex-end', textAlign: 'right',
						whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
					}}
				>
					{item._Serie}
				</Typography>
			</Box>
			<Box display="flex" flexGrow={1} flexDirection="row" alignItems="center">
				<Typography
					style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
					variant="subtitle2" color="textSecondary"
				>
					{item._Description}
				</Typography>
				<Typography variant="subtitle2" color="textSecondary"
					style={{
						marginRight: theme.spacing(1),
						justifySelf: 'flex-end', textAlign: 'right'
					}}>
					{item._Unit}
				</Typography>
			</Box>

		</Box>
	)

	const handleProduct = (theValue) => {

		if (theValue === null) {
			setSelectedProduct(theValue)
			setCurrentItem({
				_Label: '',
				_Description: '',
				_Picture: '',
				_Unit: '',
				_ColorI: '', _ColorM: '', _ColorE: '',
				_Serie: '',
				_Quantity: ''
			})
			return
		}

		setSelectedProduct(theValue)
		setCurrentItem({
			_Label: theValue._Label,
			_Description: theValue._Description,
			_Picture: theValue._Picture,
			_Unit: theValue._Unit,
			_ColorI: '', _ColorM: '', _ColorE: '',
			_Serie: theValue._Serie,
			_Quantity: ''
		})
	}

	const handleItem = () => {

		if (itemsAction === 'update') {

			setRecord({
				...record,
				_Items: record._Items.map(elem => (
					`${elem._Label}|${elem._Description}|${elem._Serie}` === `${currentItem._Label}|${currentItem._Description}|${currentItem._Serie}` ? { ...currentItem, _Quantity: parseFloat(currentItem._Quantity) } : elem
				))
			})
			setItemsAction('add')
			setCurrentItem({
				_Label: '',
				_Description: '',
				_Picture: '',
				_Unit: '',
				_ColorI: '', _ColorM: '', _ColorE: '',
				_Serie: '',
				_Quantity: ''
			})
			setSelectedProduct({
				_id: '', _Label: '', _Description: '', _Picture: '', _Unit: '',
				_ColorI: false, _ColorM: false, _ColorE: false, _Serie: '',
			})
			return
		}

		if (currentItem._Label === '' || currentItem._Quantity === '') {
			enqueueSnackbar("Please select a product and specify a quantity first", { variant: 'warning' })
			return
		}
		if ((selectedProduct._ColorI && currentItem._ColorI === '') || (selectedProduct._ColorM && currentItem._ColorM === '') || (selectedProduct._ColorE && currentItem._ColorE === '')) {
			enqueueSnackbar("Please select the colors first", { variant: 'warning' })
			return
		}
		if (record._Items.filter((elem) => {
			return `${elem._Label}|${elem._Description}|${elem._Serie}` === `${currentItem._Label}|${currentItem._Description}|${currentItem._Serie}`
		}).length !== 0) {
			enqueueSnackbar("Sorry this product has already been added to the list of items", { variant: 'warning' })
			return
		}

		setRecord({ ...record, _Items: [...record._Items, { ...currentItem, _Quantity: parseFloat(currentItem._Quantity) }] })
		setItemsAction('add')
		setCurrentItem({
			_Label: '',
			_Description: '',
			_Picture: '',
			_Unit: '',
			_ColorI: '', _ColorM: '', _ColorE: '',
			_Serie: '',
			_Quantity: ''
		})
		setSelectedProduct({
			_id: '', _Label: '', _Description: '', _Picture: '', _Unit: '',
			_ColorI: false, _ColorM: false, _ColorE: false, _Serie: '',
		})
	}

	const handleItemUpdate = anItem => {

		setItemsAction('update')
		setCurrentItem(anItem)
		setSelectedProduct(activeProducts.filter(elem => (
			`${elem._Label}|${elem._Description}|${elem._Serie}` === `${anItem._Label}|${anItem._Description}|${anItem._Serie}`
		))[0] || [])
	}

	//
	// ─── EFFECTS ────────────────────────────────────────────────────────────────────
	// Get products and colors
	useEffect(() => {
		axiosSiteData.get(`/products/list/active`)
			.then(response => {
				setActiveProducts(response.data)
			})
		axiosSiteData.get(`/colors/search/-all`, axiosSiteDataConfig)
			.then(response => {
				setColors(response.data)
			})
	}, [setActiveProducts, setColors])

	// Reset items form everytime we go back to add
	useEffect(() => {

		if (itemsAction !== 'add') return
		setCurrentItem({
			_Label: '',
			_Description: '',
			_Picture: '',
			_Unit: '',
			_ColorI: '', _ColorM: '', _ColorE: '',
			_Serie: '',
			_Quantity: ''
		})
		setSelectedProduct({
			_id: '', _Label: '', _Description: '', _Picture: '', _Unit: '',
			_ColorI: false, _ColorM: false, _ColorE: false, _Serie: '',
		})

		return
	}, [itemsAction])


	return (
		<Grid item xs={12}>
			<Card>
				<CardContent>
					{/* Card Header, Title & Back Button */}
					<Box display="flex" flexDirection="row" alignItems="center">
						<IconButton onClick={() => setContent('list')} style={{ marginRight: theme.spacing(1) }}>
							<IconArrowBackIos />
						</IconButton>
						<Typography variant="h5" style={{ flexGrow: 1 }}>
							{content === "add" ?
								<FormattedMessage id="Quotations.FormTitleAdd2" defaultMessage="Add an quotation" /> :
								<FormattedMessage id="Quotations.FormTitleUpdate" defaultMessage="Edit quotation {record}" values={{ record: <Typography component="span" variant="h5" color="primary">{`#${record._Number}`}</Typography> }} />
							}
						</Typography>
						{(content !== 'add' && panelUser.type === 'admin') &&
							<Typography variant="h5">{`${record._User._Name}, ${record._User._Company}`}</Typography>
						}
					</Box>
					{/* Quotations Form */}
					<form>
						<Grid container spacing={1} style={{ marginBottom: theme.spacing(0.001) }}>
							<Grid item xs={12} md={8}>
								{/** Field Reference */}
								<FormControl fullWidth margin="dense">
									<TextField
										label={<FormattedMessage id="Quotations.Columns._Reference" defaultMessage="Reference" />}
										id="_Reference"
										variant="outlined"
										fullWidth
										margin="dense"
										required
										value={record._Reference}
										onChange={event => setRecord({ ...record, _Reference: event.target.value })}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={2}>
								{/** Field Date */}
								<FormControl fullWidth margin="dense">
									<TextField
										label={<FormattedMessage id="Date" defaultMessage="Date" />}
										id="_CreatedAt"
										variant="outlined"
										fullWidth
										margin="dense"
										disabled
										value={content !== 'add' ? new Date(record._CreatedAt).toLocaleDateString() : new Date().toLocaleDateString()}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={2}>
								{/** Field Status */}
								<FormControl fullWidth margin="dense">
									<TextField
										label={<FormattedMessage id="Quotations.Columns._Status" defaultMessage="Status" />}
										id="_Status"
										variant="outlined"
										fullWidth
										margin="dense"
										disabled
										value={record._Status}
										onChange={event => setRecord({ ...record, _Status: event.target.value })}
									/>
								</FormControl>
							</Grid>
						</Grid>

						<Grid container spacing={1} alignItems="center">
							<Grid item xs={12}>
								{/** Field Description */}
								<FormControl fullWidth margin="dense" style={{ flexGrow: 1 }}>
									<TextField
										label={<FormattedMessage id="Quotations.Columns._Description" defaultMessage="Description" />}
										id="_Description"
										variant="outlined"
										fullWidth
										margin="dense"
										required
										value={record._Description}
										onChange={event => setRecord({ ...record, _Description: event.target.value })}
									/>
								</FormControl>
							</Grid>
						</Grid>

						{/** Items */}
						<Box width={1} mt={1} p={1} borderRadius={4} border="outset">
							<Typography variant="body2" color="primary" style={{ fontStyle: 'italic', marginBottom: theme.spacing(1) }}>Items</Typography>

							{/** Product */}
							<Grid container spacing={2} alignItems="center" >
								<Grid item xs={12} md={3}>
									<MUIAutocomplete
										variant="outlined"
										fullWidth
										label={<FormattedMessage id="Products.Columns._Label" defaultMessage="Label" />}
										margin="dense"
										style={{ marginTop: 0, marginBottom: 0 }}
										id="combo-select-product-label"
										itemHeight={60}
										listHeight={200}
										itemsWidth={350}
										items={activeProducts}
										value={selectedProduct._Label}
										setValue={search => setSelectedProduct({ ...selectedProduct, _Label: search })}
										itemRender={itemRender}
										setSelecetdItemLabel={item => {

											handleProduct(item)
											return item._Label
										}}
										onSearch={search => activeProducts.filter(elem => elem._Label.toLowerCase().includes(search.trim().toLowerCase()))}
									/>
								</Grid>
								<Grid item xs={12} md={5}>
									{/** Field Product Description */}
									<MUIAutocomplete
										variant="outlined"
										fullWidth
										label={<FormattedMessage id="Products.Columns._Description" defaultMessage="Description" />}
										margin="dense"
										style={{ marginTop: 0, marginBottom: 0 }}
										id="combo-select-product-label"
										itemHeight={60}
										listHeight={200}
										itemsWidth={350}
										items={activeProducts}
										value={selectedProduct._Description}
										setValue={search => setSelectedProduct({ ...selectedProduct, _Description: search })}
										itemRender={itemRender}
										setSelecetdItemLabel={item => {

											handleProduct(item)
											return item._Description
										}}
										onSearch={search => activeProducts.filter(elem => elem._Description.toLowerCase().includes(search.trim().toLowerCase()))}
									/>
								</Grid>
								<Grid item xs={12} md={2}>
									{/** Field Product Unit */}
									<TextField
										label={<FormattedMessage id="Products.Columns._Unit" defaultMessage="Unit" />}
										id="_Product-_Unit"
										variant="outlined"
										fullWidth
										margin="dense"
										disabled
										value={currentItem._Unit}
									/>
								</Grid>
								<Grid item xs={12} md={2}>
									{/** Field Quantity */}
									<TextField
										label={<FormattedMessage id="Quotations.Columns._Items._Quantity" defaultMessage="Qty" />}
										id="_Quantity"
										variant="outlined"
										fullWidth
										required
										margin="dense"
										type="number"
										value={currentItem._Quantity}
										onChange={event => setCurrentItem({
											...currentItem,
											_Quantity: (event.target.value !== '' && parseFloat(event.target.value) <= 1) ? 1 : event.target.value
										})}
									/>
								</Grid>
							</Grid>

							{/** Colors */}
							{currentItem._Label !== '' && <Grid container spacing={2} alignItems="center">
								{selectedProduct._ColorI && <Grid item xs={12} md={4}>
									<Autocomplete
										id="combo-select-colorI"
										options={colors.map(elem => elem._Label)}
										getOptionLabel={(option) => option}
										value={currentItem._ColorI}
										onChange={(_, newValue) => setCurrentItem({ ...currentItem, _ColorI: newValue === null ? '' : newValue })}
										renderInput={(params) => <TextField {...params} style={{ marginTop: 0, marginBottom: 0 }}
											label={<FormattedMessage id="Quotations.Columns._Items._ColorI" defaultMessage="Interior color" />} variant="outlined" margin="dense" />}
									/>
								</Grid>}
								{selectedProduct._ColorM && <Grid item xs={12} md={4}>
									<Autocomplete
										id="combo-select-colorM"
										options={colors.map(elem => elem._Label)}
										getOptionLabel={(option) => option}
										value={currentItem._ColorM}
										onChange={(_, newValue) => setCurrentItem({ ...currentItem, _ColorM: newValue === null ? '' : newValue })}
										renderInput={(params) => <TextField {...params} style={{ marginTop: 0, marginBottom: 0 }}
											label={<FormattedMessage id="Quotations.Columns._Items._ColorM" defaultMessage="Middle color" />} variant="outlined" margin="dense" />}
									/>
								</Grid>}
								{selectedProduct._ColorE && <Grid item xs={12} md={4}>
									<Autocomplete
										id="combo-select-colorE"
										options={colors.map(elem => elem._Label)}
										getOptionLabel={(option) => option}
										value={currentItem._ColorE}
										onChange={(_, newValue) => setCurrentItem({ ...currentItem, _ColorE: newValue === null ? '' : newValue })}
										renderInput={(params) => <TextField {...params} style={{ marginTop: 0, marginBottom: 0 }}
											label={<FormattedMessage id="Quotations.Columns._Items._ColorE" defaultMessage="Exterior color" />} variant="outlined" margin="dense" />}
									/>
								</Grid>}
							</Grid>}

							<Box display="flex" justifyContent="center" my={2}>
								{/** Cancel Button */}
								{itemsAction !== 'add' &&
									<Button variant="contained" color="default" style={{ marginRight: theme.spacing(2) }}
										onClick={() => {
											setItemsAction('add')
										}}
									>
										<FormattedMessage id="FormCancel" defaultMessage="Cancel" />
									</Button>}
								{/** Add/Update Button */}
								<Button variant="contained" color="secondary" onClick={() => handleItem()}>
									{itemsAction === 'add' ? <FormattedMessage id="FormAdd" defaultMessage="ADD" /> : <FormattedMessage id="FormUpdate" defaultMessage="UPDATE" />}
								</Button>
							</Box>
							{/** Selected products list */}
							<TableContainer component={Paper}>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell><FormattedMessage id="Products.Columns._Label" defaultMessage="Label"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Products.Columns._Description" defaultMessage="Description"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Products.Columns._Unit" defaultMessage="Unit"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Quotations.Columns._Items._Quantity" defaultMessage="Quantity"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Quotations.Columns._Items._ColorI" defaultMessage="Interior color"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Quotations.Columns._Items._ColorM" defaultMessage="Middle color"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="Quotations.Columns._Items._ColorE" defaultMessage="Exterior color"></FormattedMessage ></TableCell>
											<TableCell><FormattedMessage id="TableActions" defaultMessage="Actions"></FormattedMessage ></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{record._Items.map((anItem) => (
											<TableRow key={`${anItem._Label}|${anItem._Description}|${anItem._Serie}`}>
												<TableCell>{anItem._Label}</TableCell>
												<TableCell>{anItem._Description}</TableCell>
												<TableCell>{anItem._Unit}</TableCell>
												<TableCell>{anItem._Quantity}</TableCell>
												<TableCell>{anItem._ColorI}</TableCell>
												<TableCell>{anItem._ColorM}</TableCell>
												<TableCell>{anItem._ColorE}</TableCell>
												<TableCell>
													<IconButton color="secondary"
														onClick={_ => handleItemUpdate(anItem)}
													>
														<IconUpdate />
													</IconButton>
													<IconButton color="secondary"
														onClick={_ => {
															setItemsAction('add')
															setRecord({ ...record, _Items: record._Items.filter(elem => elem._Label !== anItem._Label) })
														}}
													>
														<IconDelete />
													</IconButton>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						{/** Field Observations */}
						<FormControl fullWidth margin="dense">
							<TextField
								label={<FormattedMessage id="Quotations.Columns._Observations" defaultMessage="Observations" />}
								id="_Observations"
								variant="outlined"
								fullWidth
								multiline
								margin="dense"
								required
								value={record._Observations}
								onChange={event => setRecord({ ...record, _Observations: event.target.value })}
							/>
						</FormControl>



						{/** Button SAVE */}
						<FormGroup row={true} style={{ marginTop: theme.spacing(4) }}>
							<Button variant="contained" color="default" disabled={loader}
								onClick={() => {
									if (handleSubmit(record) !== false) {
										setLoader(true)
									}
								}}>
								{loader ? <Loader /> : (content === 'add' ? <FormattedMessage id="FormSave" defaultMessage="SAVE" /> : <FormattedMessage id="FormUpdate" defaultMessage="UPDATE" />)}
							</Button>
							{/** Button SAVE AND SUBMIT*/}
							<Button variant="contained" color="primary" disabled={loader}
								style={{ marginLeft: theme.spacing(4) }}
								onClick={() => {
									if (handleSubmit({ ...record, _Status: 'Submetida' }) !== false) {
										setLoader(true)
									}
								}}>
								{loader ? <Loader /> : (content === 'add' ? <FormattedMessage id="FormSaveSubmit" defaultMessage="SAVE AND SUBMIT" /> : <FormattedMessage id="FormUpdateSubmit" defaultMessage="UPDATE AND SUBMIT" />)}
							</Button>
						</FormGroup>
					</form>
				</CardContent>
			</Card>
		</Grid>
	)
}