import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, useTheme, Typography, Box } from '@material-ui/core'

// Libs
import { FormattedMessage } from 'react-intl'

// Pages
import Header from './Header'
import Footer from './Footer'

// Others
import { projectName, langMessages, axiosSiteData, GetCatalogLink } from '../../../variables'
import { UserConnectedContext, UtilitiesContext } from '../../../contexts'


function Catalogs() {

    const { userConnected } = useContext(UserConnectedContext)
    const { APIError } = useContext(UtilitiesContext)
    const [catalogs, setCatalogs] = useState([])
    const theme = useTheme()

    //
    // ─── EFFECTS ────────────────────────────────────────────────────────────────────
    useEffect(() => {
        axiosSiteData.get(`/catalogs/${(new Date()).toJSON()}`)
            .then(response => {
                setCatalogs(response.data.sort((a, b) => {
                    return a._Name.toLowerCase() < b._Name.toLowerCase() ? -1 : a._Name.toLowerCase() > b._Name.toLowerCase() ? 1 : 0
                }))
            })
            .catch(errors => {
                APIError(errors)
            })
    }, [APIError])


    document.title = projectName + ` - ${langMessages.messages?.[userConnected.lang]?.['Website.Menu.Catalog'] || 'Catalogs'}`

    return (
        <Fragment>
            <Header />
            <Container maxWidth="md" style={{ flexGrow: 1, padding: theme.spacing(10, 4) }}>
                <Typography variant="h2" style={{ marginBottom: theme.spacing(2) }}>
                    <FormattedMessage id="Website.Menu.Catalog" defaultMessage="Catalogs" />
                </Typography>
                <Box display="flex" flexDirection="column">
                    {catalogs.map(item => (
                        <a key={item._id} href={GetCatalogLink(item._Link)}
                            style={{ margin: theme.spacing(1, 0) }}
                            target="_blank" rel="noopener noreferrer"
                        >
                            {item._Name}
                        </a>
                    ))}
                </Box>
            </Container>
            <Footer />
        </Fragment>
    )
}

export default Catalogs