import React, { Fragment } from 'react'
import { Box } from '@material-ui/core'

// Libs
import BodyBuilder from './Builder/BodyBuilder'

// Pages
import Header from './Header'
import Footer from './Footer'

// Others
import { projectName } from '../../../variables'

function Home() {

    document.title = projectName

    return (
        <Fragment>
            <Header />
            <Box flexGrow={1}>
                <BodyBuilder bodyEntry="71a7eb36b85a4749a195f531ea423c43" />
            </Box>
            <Footer />
        </Fragment>
    )
}

export default Home