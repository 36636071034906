import React, { useState } from 'react'
import {  IconButton, Button, TextField, CardContent, Card, FormControl, FormGroup, Typography, Box, Grid }from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Loader from '../../Components/Loader'
import { FormattedMessage } from 'react-intl'

import IconArrowBackIos from '@material-ui/icons/ArrowBackIosTwoTone'


export default function ContentForm({selectedRecord, content, setContent, handleSubmit}) {

    const [record, setRecord] = useState(selectedRecord)
    const [loader, setLoader] = useState(false)
    const theme = useTheme()

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    {/* Card Header, Title & Back Button */}
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <IconButton onClick={() => setContent('list')} style={{marginRight: theme.spacing(1)}}>
                            <IconArrowBackIos/>
                        </IconButton>
                        <Typography variant="h5">
                            {content === "add" ? 
                                <FormattedMessage id="Colors.FormTitleAdd" defaultMessage="Add a color" /> :
                                <FormattedMessage id="Colors.FormTitleUpdate" defaultMessage="Edit color {record}" values={{ record: <Typography component="span" variant="h5" color="primary">{record._Label}</Typography>}} />
                            }
                        </Typography>
                    </Box>
                    {/* Colors Form */}
                    <form>

                        
					{/** Field Label */}
					<FormControl fullWidth margin="normal">
						<TextField
							label={<FormattedMessage id="Colors.Columns._Label" defaultMessage="Label" />}
							id="_Label"
							placeholder="Label"
							variant="outlined"
							fullWidth
							required
							value={record._Label}
							onChange={event => setRecord({...record, _Label: event.target.value})}
						/>
					</FormControl>

				
                        
                        {/** Button Submit */}
                        <FormGroup row={true} style={{marginTop: theme.spacing(4)}}>
                            <Button variant="contained" color="primary" disabled={loader} fullWidth onClick={() => {
                                if (handleSubmit(record) !== false) {
                                    setLoader(true)
                                }
                            }}>
                                {loader ? <Loader /> : (content === 'add' ? <FormattedMessage id="FormSave" defaultMessage="SAVE" /> : <FormattedMessage id="FormUpdate" defaultMessage="UPDATE" />)}
                            </Button>
                        </FormGroup>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}