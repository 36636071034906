import React from 'react'
import { Box, Divider, Typography, useMediaQuery } from '@material-ui/core'
import Chart from 'react-apexcharts'
import { useTheme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'


function Quotations({ record }) {

    const theme = useTheme()
    const inMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box display="flex" flexDirection="column" bgcolor={theme.palette.background.paper} boxShadow={4} borderRadius={8}
            p={inMobile ? 2 : 4} pt={1} m={inMobile ? 0 : 2} mt={inMobile ? 2 : 0}
        >
            <Typography variant={inMobile ? 'h6' : 'h4'} color="primary" style={{ marginBottom: theme.spacing(1) }}>
                <FormattedMessage id="Dashboard.QuotationsByStatus" defaultMessage="Quotations by status" />
            </Typography>
            <Divider variant="fullWidth" />
            <Box height={theme.spacing(2)} />

            <Chart options={{
                labels: ['Rascunho', 'Submetida', 'Terminado'],
                colors: ['#1e90ff', '#fa8231', '#2ed573'],
                responsive: [{
                    breakpoint: theme.breakpoints.values.md,
                    options: {
                        legend: {
                            show: false,
                        }
                    }
                }]
            }}
                series={[record.Rascunho, record.Submetida, record.Terminado]}
                type="donut" width="95%" height={inMobile ? 200 : 'auto'} />
        </Box>
    )
}

export default Quotations