import React, { useState } from 'react'
import { IconButton, Button, TextField, CardContent, Card, FormControl, FormGroup, Typography, Box, Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Loader from '../../Components/Loader'
import { FormattedMessage } from 'react-intl'

import IconArrowBackIos from '@material-ui/icons/ArrowBackIosTwoTone'


export default function ContentForm({ selectedRecord, content, setContent, handleSubmit }) {

	const [record, setRecord] = useState(selectedRecord)
	const [loader, setLoader] = useState(false)
	const theme = useTheme()

	return (
		<Grid item xs={12}>
			<Card>
				<CardContent>
					{/* Card Header, Title & Back Button */}
					<Box display="flex" flexDirection="row" alignItems="center">
						<IconButton onClick={() => setContent('list')} style={{ marginRight: theme.spacing(1) }}>
							<IconArrowBackIos />
						</IconButton>
						<Typography variant="h5">
							{content === "add" ?
								<FormattedMessage id="Contacts.FormTitleAdd" defaultMessage="Add a contact" /> :
								<FormattedMessage id="Contacts.FormTitleUpdate" defaultMessage="Edit contact {record}" values={{ record: <Typography component="span" variant="h5" color="primary">{record._Name}</Typography> }} />
							}
						</Typography>
					</Box>
					{/* Contacts Form */}
					<form>


						{/** Field Name */}
						<FormControl fullWidth margin="normal">
							<TextField
								label={<FormattedMessage id="Contacts.Columns._Name" defaultMessage="Name" />}
								id="_Name"
								disabled
								placeholder="Name"
								variant="outlined"
								fullWidth
								required
								value={record._Name}
								onChange={event => setRecord({ ...record, _Name: event.target.value })}
							/>
						</FormControl>


						{/** Field Email */}
						<FormControl fullWidth margin="normal">
							<TextField
								label={<FormattedMessage id="Contacts.Columns._Email" defaultMessage="Email" />}
								id="_Email"
								disabled
								placeholder="Email"
								variant="outlined"
								fullWidth
								required
								value={record._Email}
								onChange={event => setRecord({ ...record, _Email: event.target.value })}
							/>
						</FormControl>


						{/** Field Company */}
						<FormControl fullWidth margin="normal">
							<TextField
								label={<FormattedMessage id="Contacts.Columns._Company" defaultMessage="Company" />}
								id="_Company"
								disabled
								placeholder="Company"
								variant="outlined"
								fullWidth
								required
								value={record._Company}
								onChange={event => setRecord({ ...record, _Company: event.target.value })}
							/>
						</FormControl>


						{/** Field Phone */}
						<FormControl fullWidth margin="normal">
							<TextField
								label={<FormattedMessage id="Contacts.Columns._Phone" defaultMessage="Phone" />}
								id="_Phone"
								disabled
								placeholder="Phone"
								variant="outlined"
								fullWidth
								required
								value={record._Phone}
								onChange={event => setRecord({ ...record, _Phone: event.target.value })}
							/>
						</FormControl>


						{/** Field Motivation */}
						<FormControl fullWidth margin="normal">
							<TextField
								label={<FormattedMessage id="Contacts.Columns._Motivation" defaultMessage="Motivation" />}
								id="_Motivation"
								disabled
								placeholder="Motivation"
								variant="outlined"
								fullWidth

								value={record._Motivation}
								onChange={event => setRecord({ ...record, _Motivation: event.target.value })}
							/>
						</FormControl>


						{/** Field Message */}
						<FormControl fullWidth margin="normal">
							<TextField
								label={<FormattedMessage id="Contacts.Columns._Message" defaultMessage="Message" />}
								id="_Message"
								disabled
								placeholder="Message"
								variant="outlined"
								fullWidth

								value={record._Message}
								onChange={event => setRecord({ ...record, _Message: event.target.value })}
							/>
						</FormControl>



						{/** Button Submit */}
						{!record._Converted && <FormGroup row={true} style={{ marginTop: theme.spacing(4) }}>
							<Button variant="contained" color="primary" disabled={loader} fullWidth onClick={() => {
								if (handleSubmit(record) !== false) {
									setLoader(true)
								}
							}}>
								{loader ? <Loader /> : (content === 'add' ? <FormattedMessage id="FormSave" defaultMessage="SAVE" /> : <FormattedMessage id="FormConvert" defaultMessage="CONVERT" />)}
							</Button>
						</FormGroup>}
					</form>
				</CardContent>
			</Card>
		</Grid>
	)
}