import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Box, CircularProgress, Grid } from '@material-ui/core'
// import { useTheme } from '@material-ui/core/styles'
// import { FormattedMessage } from 'react-intl'

import { PanelUserContext } from 'contexts'

import { axiosSiteData, axiosSiteDataConfig } from 'variables'

//
// ─── ICONS ──────────────────────────────────────────────────────────────────────
/* import IconAdd from '@material-ui/icons/AddTwoTone'
import IconSearch from '@material-ui/icons/SearchTwoTone' */

// Views
import Orders from './Orders'
import Quotations from './Quotations'
import Users from './Users'


function UserDashboard({ record }) {

    return (
        <Box m={3} p={1} flexGrow={1} display="flex" flexDirection="column">
            <Grid container
                style={{ flexGrow: 1 }}
            >
                {/** Orders */}
                <Grid item xs={12} md={6}>
                    <Orders record={record.Orders} />
                </Grid>
                {/** Quotations */}
                <Grid item xs={12} md={6}>
                    <Quotations record={record.Quotations} />
                </Grid>
            </Grid>
        </Box>
    )
}

function AdminDashboard({ record }) {

    return (
        <Box m={3} p={1} flexGrow={1} display="flex" flexDirection="column">
            <Grid container
                style={{ flexGrow: 1 }}
            >
                {/** Orders */}
                <Grid item xs={12} md={6}>
                    <Orders record={record.Orders} />
                </Grid>
                {/** Quotations */}
                <Grid item xs={12} md={6}>
                    <Quotations record={record.Quotations} />
                </Grid>
            </Grid>
            <Grid container >
                {/** Users */}
                <Grid item xs={12}>
                    <Users record={record.Users} />
                </Grid>
            </Grid>
        </Box>
    )
}


// Dashboard module
export default function Dashboard() {
    // const [content, setContent] = useState('list')      // list | add | edit | delete
    // const theme = useTheme()
    const { panelUser } = useContext(PanelUserContext)

    const [record, setRecord] = useState(null)

    useEffect(() => {
        axiosSiteData.get(`/stats/dashboard`, axiosSiteDataConfig)
            .then(response => {
                setRecord(response.data)
            })
    }, [])

    return (
        <Fragment>

            {/** User dashboard */}
            {(record && panelUser.type !== 'admin') &&
                <UserDashboard record={record} />
            }
            {/** Admin dashboard */}
            {(record && panelUser.type === 'admin') &&
                <AdminDashboard record={record} />
            }
            {!record &&
                <Box m={3} p={1} flexGrow={1} display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" />
                </Box>
            }
        </Fragment>
    )
}
