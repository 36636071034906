import { createContext } from 'react'

export const UserConnectedContext = createContext()
export const PanelUserContext = createContext()
export const LoggedInContext = createContext()
export const UtilitiesContext = createContext()

// Default data
export const UsersContext = createContext()
export const SeriesContext = createContext()
export const ColorsContext = createContext()
export const CatalogsContext = createContext()
export const DocumentsContext = createContext()
export const ProductsContext = createContext()
export const ContactsContext = createContext()
export const OrdersContext = createContext()
export const QuotationsContext = createContext()
