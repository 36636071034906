import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'

import { projectName } from '../../variables'

// import Lottie from 'react-lottie'
import Logo from '../../res/images/logo.png'
// ====================================================

// ====================================================

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
})


function Splash() {
  const theme = useTheme()
  const classes = useStyles()

  document.title = projectName + " - Loading ..."
  return (
    <Box className={classes.main} bgcolor={theme.palette.background.default}>
      <img width="40%" src={Logo} alt="Alumace" style={{ marginBottom: theme.spacing(8), borderRadius: 60 }} />
      <CircularProgress color="primary" variant="indeterminate" />
    </Box>
  )
}
export default Splash;