import React, { Fragment, useEffect, useState } from 'react'

import { Typography, Box, Card, CardContent, Button } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

// import { UserConnectedContext } from '../../contexts'
import { langLink, langMessages, SetLangMessages } from 'variables'
import Loader from 'Views/Components/Loader'

// Libs
import JSONEditor from 'jsoneditor'
import 'res/libs/jsoneditor/jsoneditor.min.css'
import { useSnackbar } from 'notistack'

// Views
import LoginManager from './LoginBG'


//Language
function LanguageManager({ working, setWorking }) {

    const theme = useTheme()
    // const { userConnected } = useContext(UserConnectedContext)
    const { enqueueSnackbar } = useSnackbar()

    const [editor, setEditor] = useState()

    useEffect(() => {

        const container = document.getElementById("jsoneditor")
        const options = {
            mode: 'code',
            language: 'pt-BR',
            modes: ['code', 'form', 'tree', 'view']
        }
        const editr = new JSONEditor(container, options)
        setEditor(editr)

        // load editor
        editr.set(langMessages)
    }, [])
    /* 
        const handleLangChange = (content) => {
            if (!content.error) {
                langData = content.json
            }
        } */

    const handleLangSubmit = () => {

        let langData
        try {
            langData = JSON.stringify(editor.get())
        } catch (error) {
            enqueueSnackbar('There are some errors in the json lang data.\n Please solve them first', { variant: 'error' })
            return
        }

        setWorking(true)
        SetLangMessages(
            langData,
            () => {
                
                caches.open('v1').then(function (cache) {
                    cache.delete(langLink).then(function (response) {
                        alert('The page will refresh to apply new language data')
                        window.location.reload()
                    });
                })
            },
            () => {
                setWorking(false)
            }
        )

    }

    return (

        <Fragment>
            <Typography color="primary" style={{ marginBottom: theme.spacing(2) }}><FormattedMessage id="Language" defaultMessage="Language" /></Typography>
            <Box height={550} id="jsoneditor"></Box>
            <Button style={{ marginTop: theme.spacing(1) }} disabled={working} color="primary" variant="contained" onClick={_ => handleLangSubmit()}>
                {working ? <Loader /> : <FormattedMessage id="SaveLanguage" defaultMessage="Save language" />}
            </Button>
        </Fragment>
    )
}

// Root
function Settings() {
    // ─── CONTEXTS ───────────────────────────────────────────────────────────────────
    // const classes = useStyles()
    const [working, setWorking] = useState(false)
    const theme = useTheme()

    // ─── STATES ─────────────────────────────────────────────────────────────────────

    return (
        <Box m={3} p={1} flexGrow={1}>
            <Card>
                <CardContent>
                    <LanguageManager working={working} setWorking={setWorking} />
                </CardContent>
            </Card>

            <Card style={{ marginTop: theme.spacing(2)  }}>
                <CardContent>
                    <LoginManager working={working} setWorking={setWorking} />
                </CardContent>
            </Card>
        </Box>
    )
}

export default Settings
